.home-section {
    margin-bottom: 35px;
    .btn-default {
        display: block;
        padding: 10px 15px;
        width: 100%;
        background-color: #f8f8f8;
        color: #2a2a2a;
        border: 1px solid #efefef;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        text-align: center;
        margin-top: 30px;
        box-shadow: none;
    }
    @include media-breakpoint-down(md) {
        .partner-info {
            margin-bottom: 0!important;
        }
    }
    @include media-breakpoint-down(sm) {
        .partner-info {
            margin-top: 30px;
            margin-bottom: 0 !important;
            > h3 {
                display: none !important;
            }
        }
        .progress {
            margin: 0 auto !important;
        }
        .well {
            padding-top: 25px;
            .payment-info {
                margin-top: 35px !important;
            }
        }

    }

}
