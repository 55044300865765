#header {
  div.menu {
    .top-menu {
      &[data-depth='2'] {
        &>div {
          width:25%;
          vertical-align: top;
          @include media-breakpoint-down(md) {
            width:50%;
          }
          @include media-breakpoint-down(sm) {
            width:100%;
          }
        }
      }
      display: inline-block;
      margin-bottom: 0;
      li {
        display: inline-block;
      }
      li.category {
        font: 400 14px $font-family;
        background-color: $brand-primary;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        img {
          display: inline-block;
          margin-left: 7px;
          margin-right: 6px;
          vertical-align: 0px;
        }
        a {
          color: #fff;
          display: inline-block;
          width: auto;
          padding: 10px 18px;
          min-width: 275px;
          text-transform: none;
          &:after {

            content: '';
            background-image: url('../img/down-arrow.png');
            background-repeat: no-repeat;
            display: inline-block;
            font-family: $font-awesome;
            font-size: 14px;
            width: 13px;
            margin-top: 6px;
            height: 15px;
            font-weight: 900;
            float: right;
          }
          &:hover {
            color: #fff;
          }
        }
        @include media-breakpoint-up(md) {
          &:hover {
            div.sub-menu {
              display: inline-block;
            }
          }
        }
      }
    }

    div.sub-menu {
      position: absolute;
      top: 40px;
      left: 0;
      border-radius: 0;
      z-index: 997;
      min-width: auto;
      .top-menu {
        display: inline-block;
        width: 275px;
        padding: 20px 0;
        background-color: #fff;
        li.category {
          display: block;
          background-color: #fff;
          border-radius: unset;
          img {
            display: inline-block;
            vertical-align: -3px;
            margin-left: 7px;
            margin-right: 5px;
          }
          a {
            font: 300 14px $font-family;
            color: #000;
            padding: 9px 15px 9px 18px;
            min-width: 100%;
            &:after {
              content: '';
              background-image: url('../img/right-arrow.png');
              background-repeat: no-repeat;
              display: inline-block;
              font-family: $font-awesome;
              font-size: 14px;
              width: 8px;
              margin-top: 3px;
              height: 15px;
              font-weight: 900;
              float: right;
            }
            &:hover {
              color: $brand-primary;
              &:after {
                background-image: url('../img/right-arrow-red.png');
              }
            }
          }
          &:hover {
            > a {
              color: $brand-primary;
            }
            .sub-menu2 {
              display: block;
              //box-shadow: 10px 10px 46px -20px rgba(130,130,130,1);
              background-color: #e5e5e5;
              @include media-breakpoint-down(sm) {
                box-shadow: none;
              }
            }
          }
          &:after {
            content: '';
            display: block;
            width: 245px;
            margin: 0 15px;
            border-bottom: 1px solid #d3d3d3;

          }
          &:last-child {
            &:after {
              display: none;
            }
          }
        }
      }
    }
    .sub-menu2 {
      position: absolute;
      width: 925px;
      @include media-breakpoint-down(lg) {
        width: 690px;
      }
      @include media-breakpoint-down(md) {
        width: 460px;
        div {
          width: auto;
        }
      }
      display: none;
      background: white;
      left: 275px;
      top: 0;
      min-height: 100%;

      ul.top-menu {
        display: block;
        width: 100%;
        background-color: #e5e5e5;
        li.category {
          display: block;
          background-color: #e5e5e5;
          ul {
            column-count: unset;
            column-gap: unset;
            counter-reset: unset;
            height: auto;
          }
          li.category {
            width: 100%;
          }
          a {
            padding: 5px;
            white-space: normal;
            &[data-depth="2"] {
              color: $brand-danger;
            }
            &:after {
              background-image: none;
            }
            &:hover {
              &:after {
                background-image: none;
              }
            }
          }
          &:after {
            display: none;
          }
        }
        div {
          display: inline-block;
          @include media-breakpoint-down(sm){
            display: block;
          }
          ul {
            padding: 0;
            display: block;
            padding-left: 15px;
          }
        }
      }
    }
  }
}

// #index {
//   #header div.menu div.sub-menu {
//     display: inline-block;
//   }
//   #header .sticky div.menu div.sub-menu {
//     display: none;
//   }
// }

#header div.menu div.sub-menu {
  display: none;
}

a.dropdown-item[data-depth="0"]:hover {
  #header div.menu div.sub-menu {
    display: inline-block;
  }
}

#header div.menu div.sub-menu .top-menu li.category a:hover {
  div.sub-menu2 {
    display: inline-block;
  }
}

.menuToggle {
  position: relative;
}

div.show-menu {
  display: block;
}

li.link, li.cms-page {
  a {
    padding: 3px 10px;
  }
  &:first-child {
    padding-left: 40px;
  }
}

#lnk-info-client {
  padding-left: 80px;
  @include media-breakpoint-down(lg) {
    padding-left: 0;
  }
}

.menu-icon {
  padding-top: 5px;
  cursor: pointer;
  padding-right: 20px;
  font-size: 28px;
  @include media-breakpoint-down(xs) {
    padding-top: 0;
    font-size: 25px;
    padding-right: 10px;
  }
}

@include media-breakpoint-down(md) {
  .top-menu {
    li.cms-page, li.link {
      a {
        padding: 3px 10px;
      }
    }
  }
  #index {
    div.menu {
      .top-menu {
        li.category {
          div.sub-menu {
            display: none;
          }
        }
      }
    }
  }
  div.sub-menu2 .top-menu li.category div.collapse2 {
    width: 470px;
  }
  .top-menu li.cms-page a, .top-menu li.link a {
    padding: 3px 2px;
    font-size: 14px;
  }
}

@include media-breakpoint-down(sm) {
  #index {
    div.menu {
      .top-menu {
        li.category {
          div.sub-menu {
            display: block;
          }
        }
      }
    }
  }
  div.menu {
    .top-menu {
      li.category {
        .expand {
          display: none;
        }
        div.sub-menu {
          display: block;
          border-bottom: 1px solid $gray-light;
          margin-bottom: 10px;
          .expand {
            display: none;
          }
          div.sub-menu2 {
            display: block;
            .expand {
              display: block;
            }
          }
        }
      }
    }
  }

  div.sub-menu2 .top-menu li.category {
    a {
      font-weight: 600;
    }
    div.collapse2 ul {
      display: block;
      background-color: transparent;
      div.collapse2 {
        background-color: transparent;
        ul {
          display: block;
        }
      }
    }
  }
  div.sub-menu2 .top-menu li.category div.collapse2 div.collapse2 ul {
    margin-top: 0;
    margin-bottom: 0;
  }
  #header div.menu div.sub-menu .top-menu li.category:after {
    width: 91%;
  }
  div.sub-menu2 .top-menu li.category div.collapse2 {
    width: 470px;
  }
}



