@import "~bourbon/app/assets/stylesheets/bourbon";
@import "partials/_variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css";
@import "partials/_mixins";
@import "partials/_fonts";
@import "partials/_commons";
@import "components/alert";
@import "components/drop-down";
@import "components/search-widget";
@import "components/mainmenu";
@import "components/checkout";
@import "components/customer";
@import "components/forgotten-password";
@import "components/renew-password";
@import "components/imageslider";
@import "components/featuredproducts";
@import "components/custom-text";
@import "components/categories";
@import "components/products";
@import "components/cart";
@import "components/block-reassurance";
@import "components/quickview";
@import "components/stores";
@import "components/footer";
@import "components/contact";
@import "components/errors";
@import "components/customization-modal";
@import "components/sitemap";
@import "components/advancedsearchbar";
@import "components/favoriteproducts17";
@import "components/account-expanded";
@import "components/cart-expanded";
@import "components/meniu";
@import "components/new-menu";
@import "components/advancedlayerednavigation";
@import "components/filtre";
@import "components/sortare";
@import "components/sticky-menu";
@import "components/sticky-header";
@import "components/clientinfo";
@import "components/asmmanager";

/*** FONTS ***/

/* === Montserrat - 100 */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100;
    src: url("./font/Montserrat-Thin.eot");
    src: local("Montserrat Thin"),
    local("Montserrat-Thin"),
    url("./font/Montserrat-Thin.eot") format("embedded-opentype"),
    url("./font/Montserrat-Thin.woff2") format("woff2"),
    url("./font/Montserrat-Thin.woff") format("woff"),
    url("./font/Montserrat-Thin.ttf") format("truetype"),
    url("./font/Montserrat-Thin.svg") format("svg");
}

/* === Montserrat - 200 */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    src: url("./font/Montserrat-ExtraLight.eot");
    src: local("Montserrat ExtraLight"),
    local("Montserrat-ExtraLight"),
    url("./font/Montserrat-ExtraLight.eot") format("embedded-opentype"),
    url("./font/Montserrat-ExtraLight.woff2") format("woff2"),
    url("./font/Montserrat-ExtraLight.woff") format("woff"),
    url("./font/Montserrat-ExtraLight.ttf") format("truetype"),
    url("./font/Montserrat-ExtraLight.svg") format("svg");
}

/* === Montserrat - 300 */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url("./font/Montserrat-Light.eot");
    src: local("Montserrat Light"),
    local("Montserrat-Light"),
    url("./font/Montserrat-Light.eot") format("embedded-opentype"),
    url("./font/Montserrat-Light.woff2") format("woff2"),
    url("./font/Montserrat-Light.woff") format("woff"),
    url("./font/Montserrat-Light.ttf") format("truetype"),
    url("./font/Montserrat-Light.svg") format("svg");
}

/* === Montserrat - regular */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url("./font/Montserrat-Regular.eot");
    src: local("Montserrat Regular"),
    local("Montserrat-Regular"),
    url("./font/Montserrat-Regular.eot") format("embedded-opentype"),
    url("./font/Montserrat-Regular.woff2") format("woff2"),
    url("./font/Montserrat-Regular.woff") format("woff"),
    url("./font/Montserrat-Regular.ttf") format("truetype"),
    url("./font/Montserrat-Regular.svg") format("svg");
}

/* === Montserrat - 600 */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url("./font/Montserrat-SemiBold.eot");
    src: local("Montserrat SemiBold"),
    local("Montserrat-SemiBold"),
    url("./font/Montserrat-SemiBold.eot") format("embedded-opentype"),
    url("./font/Montserrat-SemiBold.woff2") format("woff2"),
    url("./font/Montserrat-SemiBold.woff") format("woff"),
    url("./font/Montserrat-SemiBold.ttf") format("truetype"),
    url("./font/Montserrat-SemiBold.svg") format("svg");
}

/* === Montserrat - 700 */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url("./font/Montserrat-Bold.eot");
    src: local("Montserrat Bold"),
    local("Montserrat-Bold"),
    url("./font/Montserrat-Bold.eot") format("embedded-opentype"),
    url("./font/Montserrat-Bold.woff2") format("woff2"),
    url("./font/Montserrat-Bold.woff") format("woff"),
    url("./font/Montserrat-Bold.ttf") format("truetype"),
    url("./font/Montserrat-Bold.svg") format("svg");
}

/* === Montserrat - 800 */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url("./font/Montserrat-ExtraBold.eot");
    src: local("Montserrat ExtraBold"),
    local("Montserrat-ExtraBold"),
    url("./font/Montserrat-ExtraBold.eot") format("embedded-opentype"),
    url("./font/Montserrat-ExtraBold.woff2") format("woff2"),
    url("./font/Montserrat-ExtraBold.woff") format("woff"),
    url("./font/Montserrat-ExtraBold.ttf") format("truetype"),
    url("./font/Montserrat-ExtraBold.svg") format("svg");
}

/* === Montserrat - 900 */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    src: url("./font/Montserrat-Black.eot");
    src: local("Montserrat Black"),
    local("Montserrat-Black"),
    url("./font/Montserrat-Black.eot") format("embedded-opentype"),
    url("./font/Montserrat-Black.woff2") format("woff2"),
    url("./font/Montserrat-Black.woff") format("woff"),
    url("./font/Montserrat-Black.ttf") format("truetype"),
    url("./font/Montserrat-Black.svg") format("svg");
}

/*** FONTS ***/
.align-self-center {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

a, button {
    &:active, &:visited, &:focus {
        text-decoration: none;
        outline: transparent !important;
    }
    &:focus {
        color: $brand-primary;
    }
}

button:focus {
    outline: none;
}

.modal-open {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

input:focus,
textarea:focus {
    -webkit-text-size-adjust: 100%;
}
.btn-primary {
    border-radius: 3px;
    font-weight: 500;
    font-size: 14px;
}
select::-ms-expand {
    display: none;
}

//@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
//    .searchresult {
//        -ms-overflow-style: none!important;
//        overflow: auto!important;
//    }
//}

.searchresult {
    -ms-overflow-style: none;
    overflow: auto;
}

:focus {
    outline: -webkit-focus-ring-color 0;
}

.input-group.focus {
    outline: transparent;
}

.form-control:focus {
    outline: transparent;
}

.no-scroll {
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.margin-70 {
    margin-top: 70px !important;
}

h1, h2 {
    text-transform: none;
    color: $brand-secondary;
}
.empty-space{
    @include media-breakpoint-down(lg){
        height:20px;
    }
    @include media-breakpoint-down(sm){
        height:0;
    }
    @include media-breakpoint-down(xs){
        height:20px;
    }
}

.btn-primary, .btn-secondary, .btn-tertiary {
    box-shadow: none;
}

.footable .footable-sortable .footable-sort-indicator::after, .footable.breakpoint > tbody > tr > td > span.footable-toggle:before {
    font-family: $font-awesome !important;
}

.tfoot td {
    font-weight: bold;
    color: #595a5c;
}

#module-seniorerpmodule-default .table thead th {
    @include media-breakpoint-down(sm) {
        padding: 5px;
    }
}

#index {
    .paddlr-7 {
        padding-left: 13px;
        padding-right: 13px;
        @include media-breakpoint-down(sm) {
            padding-left: 3px;
            padding-right: 3px;
        }
    }
}

.bootstrap-touchspin {
    box-shadow: none;
}

.hidden {
    display: none;
}

/*** HEADER ***/
#header {
    background: white;
    color: #2b2a29;
    .logo {
        max-width: 100%;
    }
    a {
        color: $brand-secondary;
        &:hover {
            text-decoration: none;
            color: $brand-primary;
        }
    }
    .header-nav {
        border-bottom: $gray-light 2px solid;
        max-height: 50px;
        margin-bottom: 30px;
        .right-nav {
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-end;
        }
        .currency-selector {
            margin-top: 0.9375rem;
            margin-left: 0.9375rem;
            white-space: nowrap;
        }
        .user-info {
            margin-left: 2.5rem;
            margin-top: 0.9375rem;
            text-align: right;
            white-space: nowrap;
            .account {
                margin-left: $small-space;
            }
        }
        .language-selector {
            margin-top: 0.9375rem;
            white-space: nowrap;
        }
        .cart-preview {
            &.active {
                background: $brand-primary;
                a, i {
                    color: white;
                }
            }
            .shopping-cart {
                vertical-align: middle;
                color: $gray;
            }
            .body {
                display: none;
            }
        }
        .blockcart {
            background: $gray-light;
            height: 3rem;
            padding: 0.75rem;
            margin-left: 0.9375rem;
            text-align: center;
            white-space: nowrap;
            a {
                color: $gray;
                &:hover {
                    color: $brand-primary;
                }
            }
            &.active {
                a:hover {
                    color: white;
                }
            }
            .header {
                margin-top: 0.125rem;
            }
        }
        #contact-link {
            margin-top: 0.9375rem;
        }
        .material-icons {
            line-height: inherit;
            &.expand-more {
                margin-left: -0.375rem;
            }
        }
    }
    .header-top {
        > .container {
            position: relative;
        }
        .position-static {
            position: static;
        }
    }

    /*** custom header***/
    #header-wrapper {
        margin-top: 25px;
        margin-bottom: 10px;
        @include media-breakpoint-down(sm) {
            margin-top: 0;
        }
    }
    #_desktop_logo h1 {
        display: inline-block;
        margin-bottom: 0;
    }
    .right-nav {
        display: inline-block;
        padding-top: 10px;
        #_desktop_user_info, #_desktop_user_favorites, #_desktop_cart {
            display: inline-block;
            padding: 0;
            padding-left: 10px;
            position: relative;
            cursor: pointer;
            @include media-breakpoint-down(lg) {
                padding-left: 38px;
            }
            @include media-breakpoint-down(md) {
                padding-left: 19px;
            }
            i {
                display: inline-block;
                margin-left: 4px;
                font-size: 12px;
                vertical-align: middle;
            }
        }
        a, span {
            font-size: 12px;
        }
    }
    #_desktop-logo {
        padding-top: 10px;
    }
    .header-icon {
        background-repeat: no-repeat;
        vertical-align: middle;
        height: 23px;
        width: 25px;
        overflow: hidden;
        display: inline-block;
    }
    .fa-search {
        font-size: 21px;
    }
    .user-icon {
        background-image: url(../img/user-icon.png);
    }
    .fav-icon {
        background-image: url(../img/fav-icon.png);
        margin-top: 2px;
    }
    .cart-icon {
        background-image: url(../img/cart-icon.png);
        margin-top: 2px;
    }
    .cart-products-count {
        text-align: center;
        position: absolute;
        top: -9px;
        right: 75px;
        height: 19px;
        min-width: 19px;
        @include border-radius(50%);
        background-color: $brand-primary;
        display: block;
        font-size: 9px;
        color: #fff !important;
        @include media-breakpoint-down(lg) {
            right: -5px;
        }
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    @include media-breakpoint-down(sm) {
        .padd-1 {
            padding-left: 20px;
        }
        .fa-search {
            padding-left: 0;
        }
    }
    @include media-breakpoint-down(xs) {
        .padd-1 {
            padding-left: 10px;
        }
        .fa-search {
            padding-left: 0;
        }
        #_mobile_logo {
            max-width: 45%;
        }
    }

    #header-right {
        float: right;
    }
    .customer-name {
        display: inline-block;
        float: right;
        font-size: 12px;
    }
    .currency-selector {
        float: right;
        margin-left: 20px;
        span {
            font-size: 12px;
            background-color: $gray-lighter;
            border-radius: 15px;
            padding: 5px 10px;
            margin-left: 10px;
        }
    }
    //#_desktop_logo {
    //    padding-top: 10px;
    //}
    @include media-breakpoint-down(sm) {
        #_desktop_logo, .right-nav, #header-right, #header-wrapper {
            padding: 0;
            height: 0;
        }
        .header-top {
            background-color: #fff;
            .mobile {
                margin-top: 15px;
                margin-bottom: 15px;
            }
            h1 {
                margin-bottom: 0;
            }
        }
        .icons-wrapper {
            margin-top: 8px;
            float: right;
            @include media-breakpoint-down(xs) {
                margin-top: 0px;
            }
        }
    }
    @include media-breakpoint-down(lg) {
        .menu-wrapper {
            margin-top: 20px;
        }
    }
    @include media-breakpoint-down(sm) {
        .menu-wrapper {
            margin-top: 0px;
        }
    }
    /*** custom header***/
}

.popover {
    font-family: inherit;
}
.js-product-images-modal {
    .fa-times {
        position: absolute;
        left: -30px;
        top: 10px;
        color: #fff;
        padding: 7px 10px;
        background: black;
        border-radius: 50%;
        font-size: 18px;
        cursor: pointer;
    }
}

/*** WRAPPER ***/
#wrapper {
    background: #fff;
    .banner {
        margin-bottom: 1.5rem;
        display: block;
        img {
            box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
        }
    }
    .breadcrumb {
        background: #fff;
        padding: 16px 20px;
        margin-bottom: 30px;
        font-size: 14px;
        border-bottom: 1px solid $gray;
        &[data-depth="1"] {
            display: none;
        }
        ol {
            padding-left: 0;
            margin-bottom: 0;
        }
        li {
            display: inline;
            font: 300 14px $font-family;
            &::after {
                content: "";
                display: inline-block;
                background-image: url("../img/right-arrow-categ.png");
                background-repeat: no-repeat;
                color: $gray;
                margin: 0.3125rem;
                width: 10px;
                height: 12px;
                vertical-align: middle;
            }
            &:last-child {
                content: "/";
                color: $gray-dark;
                margin: 0;
                a {
                    font-weight: bold;
                }
                &::after {
                    content: "";
                    background-image: none;
                }
            }
            a {
                color: $brand-secondary;
            }
        }
    }
}

.manufacturerslider17 {
    background-color: #fff;
    padding: 30px 0;
}

.in-stock {
    color: #2d8d26;
}

.out-of-stock {
    color: $brand-primary;
}

.limited-stock {
    color: #ff921d;
}

.product-stock {
    font: 500 14px $font-family;
}

/*** custom wrapper***/
.no-float {
    float: none !important;
}

#index, #category, #product {
    #wrapper {
        padding-top: 0;
    }
}

#my-account, #module-favoriteproducts17-account, #authentication, #contact,
#password, #cart, #checkout, #search, #module-seniorerpmodule-default,
#prices-drop, #module-partnerfilesmanager-Files,
#module-partnersfeeds-feeds, #manufacturer {
    #wrapper {
        padding-top: 1.563rem;
    }
}

#authentication #wrapper, #password #wrapper {
    //height: 100%;
    //min-height: 75vh;
    //display: flex;
    //align-items: center;
    position: fixed; /* or absolute */
    top: 25%;
    width: 100%;
    height: 100%;
    .ps-alert-success {
        padding-bottom: 0;
    }
}
#password #wrapper {
    min-height: 70vh;
}

#carousel_new {
    .caption {
        left: 4700px;
        top: 60px;
        color: #000;
        width: 350px;
        background-color: transparent;
        h2 {
            font-size: 30px;
            font-weight: 300;
        }
        .caption-description {
            p {
                color: #000;
                font-size: 14px;
            }
        }
    }
    //figure {
    //    img {
    //        height: 380px;
    //    }
    //}
}

.rslides_tabs {
    display: inline-block;
    position: absolute;
    bottom: 15px;
    width: 100%;
    left: calc(50% - 12px);
    width: 100px;
    z-index: 8;
    @include media-breakpoint-down(md) {
        //left: 47%;
        bottom: -10px;
    }
    li {
        display: inline-block;
        background-color: #a5a5a5;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        margin: 0 5px;
        border: 1px solid transparent;
        a {
            color: transparent;
            font-size: 20px;
        }
        &.rslides_here {
            background-color: $brand-primary;
        }
    }
}

.partnerbuttons_wrapper {
    .partnerbuttons_item {
        background-color: $brand-primary;
        color: #fff;
        border-radius: 3px;
        padding: 18px;
        width: 100%;
        @include media-breakpoint-down(md) {
            min-height: 150px;
        }
        @include media-breakpoint-down(sm) {
            margin-bottom: 30px;
            min-height: unset;
        }
        @include media-breakpoint-down(xs) {
            margin-bottom: 15px;
        }
        &:after {
            background: url('../img/hourglass.png') no-repeat;
            width: 36px;
            height: 44px;
            margin-top: 2px;
            content: "";
            display: inline-block;
            position: absolute;
            right: 40px;
            top: 37px;
        }
        .partnerbuttons_item_value {
            font: 500 30px $font-family;
            padding: 11px 0 15px 5px;
        }
        .partnerbuttons_item_link {
            font-size: 16px;
            color: #fff;
            padding: 10px 0 0 5px;
            display: inline-block;
        }
    }
    .sec-el:after {
        background: url('../img/receipt.png') no-repeat;
    }
    .third-el:after {
        background: url('../img/growth.png') no-repeat;
    }
    .fourth-el:after {
        background: url('../img/package.png') no-repeat;
        width: 45px;
    }
}

.banner1 {
    margin-bottom: 30px;
    display: inline-block;
}

.view-map {
    display: inline-block;
    font: 400 14px $font-family;
    padding: 10px 15px;
    border-color: #efefef;
    background: #fff;
    border-radius: 5%;
    &:hover {
        background-color: darken(#efefef, 10%);
    }
    i {
        margin-right: 10px;
        font-size: 16px;
    }
}

#module-partnerfilesmanager-Files {
    .card-block {
        padding: 0 15px 15px 15px;
    }
    .marketing-title {
        font: bold 16px $font-family;
        color: $brand-secondary;
    }
    .marketing-title {
        padding: 15px 0;
    }
    .marketing-article {
        padding-bottom: 5px;
        font-size: 14px;
        i {
            margin-right: 5px;
            font-size: 14px;
        }
    }
}

#module-partnersfeeds-feeds {
    .feed-wrapper {
        .card-block {
            padding: 15px;
        }
        .btn-primary {
            padding: 5px 10px;
            border-radius: 3px;
            font-size: 15px;
        }
        p {
            margin-bottom: 0;
            padding-top: 4px;
        }
    }
}
#overdueInvoices {
    margin-top: 2px;
    margin-left: 0;
}

/*** custom wrapper***/

/*** MAIN ***/
#main {
    .page-header {
        margin-bottom: 1.563rem;
    }
    .page-content {
        margin-bottom: 1.563rem;
        h6 {
            margin-bottom: 1.125rem;
        }
        #notifications {
            margin-left: -15px;
            margin-right: -15px;
        }
    }
    .page-footer {
        margin-bottom: 1.563rem;
    }
}

#notifications {
    ul {
        margin-bottom: 0;
    }
}

.images-container .slick-dots {
    width: 90%;
}

.order-options {
    label {
        text-align: left;
    }
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
    .hide-sm {
        display: none;
    }
    #wrapper {
        box-shadow: none;
        padding-top: 25px !important;
    }
    #index, #identity {
        #wrapper {
            padding-top: 0 !important;
        }
    }
    #checkout-cart-summary {
        float: none;
        width: 100%;
        margin-top: 1rem;
    }
    #header {
        background: $gray-light;
        border-bottom: 1px solid $gray;
        .header-nav {
            background: white;
            margin-bottom: 0.625rem;
            color: $gray-darker;
            .top-logo {
                line-height: 50px;
                vertical-align: middle;
                width: 200px;
                margin: 0 auto;
                a {
                    img {
                        max-height: 50px;
                        max-width: 100%;
                    }
                }
            }
            .right-nav {
                flex-direction: column;
            }
            .user-info {
                text-align: left;
                margin-left: 0;
                .logged {
                    color: $brand-primary;
                }
            }
            .blockcart {
                margin-left: 0;
                background: inherit;
                &.active {
                    margin-left: 0.5rem;
                }
                &.inactive {
                    .cart-products-count {
                        display: none;
                    }
                }
            }
        }
    }
    section.checkout-step {
        width: 100%;
    }
    .default-input {
        min-width: 100%;
    }
    label {
        clear: both;
    }
    #products .product-miniature,
    .featured-products .product-miniature {
        //margin: 0 auto;
    }
    .block-contact {
        padding-left: 0.9375rem;
        border: none;
    }
    .dropdown-item {
        padding-left: 0;
    }
    #footer {
        padding-top: 0.5rem;
    }
    .products-sort-order {
        padding-right: 0;
    }
    #search, #prices-drop, #manufacturer {
        .products-sort-order {
            padding-left: 0;
            padding-right: 10px;
        }
    }

    #module-seniorerpmodule-default #wrapper #content {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    /***custom***/
}


@include media-breakpoint-down(md) {
    .container {
        max-width: 100%;
    }
    #header .logo {
        width: auto;
    }
    #products .product-miniature,
    .featured-products .product-miniature {
    }
    #blockcart-modal .product-image {
        width: 100%;
        display: block;
        max-width: 250px;
        margin: 0 auto 0.9375rem;
    }
    #blockcart-modal .cart-content {
        padding-left: 0;
    }
    #blockcart-modal .product-name,
    #product-availability {
        margin-top: $small-space;
    }
    #search_filters .facet .facet-label {
        text-align: left;
    }
    .block-category .category-cover {
        position: relative;
        text-align: center;
    }
    .block-category {
        padding-bottom: 0;
    }
}
