#products {
    color: $gray;
    .products-select {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }
    .up {
        margin-bottom: 1rem;
        box-shadow: none;
        text-align: right;
        a {
            padding: 5px;
            box-shadow: none;
            background-color: transparent;
            border: 1px solid $gray;
            border-radius: 3px;
            font: 300 14px $font-family;
            color: $gray;
            i {
                font-size: 18px;
            }
        }
        .btn-secondary {
            color: $gray;
            text-transform: inherit;
            .material-icons {
                margin-right: 0;
            }
        }
    }
}

.block-category {
    min-height: 13.75rem;
    margin-bottom: 1.563rem;
    #category-description {
        p,
        strong {
            font-weight: 400;
            color: $gray;
        }
        p {
            color: $gray-darker;
            margin-bottom: 0;
            &:first-child {
                margin-bottom: $medium-space;
            }
        }
    }
    .category-cover {
        position: absolute;
        right: 0.75rem;
        bottom: 0;
        img {
            width: 141px;
            height: 180px;
        }
    }
}

.products-selection {
    .sort-by-row {
        display: flex;
        align-items: center;
    }
    .sort-by {
        white-space: normal;
        word-break: break-word;
        margin-right: -0.9375rem;
        margin-left: 0.9375rem;
        text-align: right;
        font-size: 16px;
        color: $gray-dark;
    }
    .total-products {
        padding-top: 0.625rem;
    }
    h1 {
        padding-top: 0.625rem;
    }

}

.products-sort-order {
    color: $gray-dark;
    padding-right: 0;
    .fa-sort {
        float: right;
        margin-top: 3px;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    .select-title {
        display: inline-block;
        width: 100%;
        color: $gray-dark;
        background: white;
        padding: 4px 7px;
        cursor: pointer;
        border: $input-btn-border-width solid $gray;
        border-radius: 3px;
        font-size: 14px;
        .fa-caret-up {
            top: 5px;
            position: absolute;
            right: 11px;
        }
        .fa-caret-down {
            bottom: 4px;
            position: absolute;
            right: 11px;
        }
        @include media-breakpoint-down(md) {
            font-size: 13px;
        }
        @include media-breakpoint-down(sm) {
            text-align: center;
            .fa-caret-up, .fa-caret-down {
                display: none;
            }
        }

    }
    .select-list {
        display: block;
        color: $gray-darker;
        padding: $small-space $medium-space;
        &:hover {
            background: $brand-primary;
            color: white;
            text-decoration: none;
        }
    }
    .dropdown-menu {
        left: auto;
        width: 185px;
        border: 1px solid $gray;
        border-radius: 6px;
        box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
        margin: 0;
        a {
            font-size: 14px;
        }
        @include media-breakpoint-down(md) {
            width: 162px;
            a {
                font-size: 13px;
            }
        }
    }
}

.view-type {
    font: 300 16px $font-family;
    color: $gray-dark;
    //margin-top: 5px;
    display: inline-flex;
    label {
        font-size: 16px;
        align-items: center;
        display: inline-flex;
        margin-bottom: 0;
        margin-right: 5px;
    }
}

.show_grid {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    margin-right: 5px;
    cursor: pointer;
    &:before {
        background: url('../img/grid_view_gray.png') no-repeat;
        width: 29px;
        height: 29px;
        content: "";
        display: inline-block;
    }
    &:hover,
    &.active {
        &:before {
            background-image: url('../img/grid_view_red.png');
        }
    }
}

.show_list {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin-right: 5px;
    cursor: pointer;
    &:before {
        background: url('../img/list_view_gray.png') no-repeat;
        width: 29px;
        height: 29px;
        margin-top: 2px;
        content: "";
        display: inline-block;
    }
    &:hover,
    &.active {
        &:before {
            background-image: url('../img/list_view_red.png');
        }
    }
}

.facet-dropdown {
    color: $gray;
    padding-left: 0;
    padding-right: 0;
    background: $gray-light;
    border: 3px solid transparent;
    box-sizing: border-box;
    box-shadow: 1px 1px 1px 1px $gray-light;
    &.open {
        border: 0;
        > .select-title {
            border: 3px solid $brand-primary;
            background: $gray-lighter;
        }
    }
    .select-title {
        display: inline-flex;
        align-items: center;
        margin-left: 0;
        width: 100%;
        color: $gray-darker;
        background: $gray-light;
        padding: $small-space $small-space $small-space $medium-space;
        cursor: pointer;
        > i {
            margin-left: auto;
        }
    }
    .select-list {
        display: block;
        color: $gray-darker;
        background: $gray-lighter;
        padding: $small-space $medium-space;
        &:hover {
            background: $brand-primary;
            color: white;
            text-decoration: none;
        }
    }
    .dropdown-menu {
        padding: 0;
        margin-top: 3px;
        left: auto;
        width: 100%;
        background: $gray-lighter;
        border: none;
        box-shadow: 1px 1px 1px 1px $gray-light;
    }
}

#search_filters,
#search_filters_brands,
#search_filters_suppliers {
    @include box-shadow;
    margin-bottom: 1.563rem;
    background: white;
    padding: 1.563rem 1.25rem;
    .facet {
        .collapse {
            display: block;
        }
        padding-top: $small-space;
        .facet-title {
            color: $gray;
        }
        .facet-label {
            margin-bottom: 0;
            a {
                margin-top: 0.4375rem;
                color: $gray-darker;
                display: inline-block;
                font-size: $font-size-lg;
            }
        }
    }
}

#search_filters_brands,
#search_filters_suppliers {
    .facet {
        padding-top: 0;
        .facet-label {
            margin-bottom: 0.3125rem;
        }
    }
}

.pagination {
    width: 100%;
    > div:first-child {
        line-height: 2.5rem;
    }
    .page-list {
        background: white;
        padding: 0.375rem;
        margin-bottom: 0;
        display: inline-flex;
        float: right;
        padding-right: 0;
        @include media-breakpoint-down(xs) {
            padding-left: 0;
            li {
                a {
                    margin-left: 0;
                    margin-right: 10px;
                    &.previous {
                        width: 10px;
                        margin-right: 20px;
                    }
                }
            }
        }
        li {
            display: inline-flex;
            float: left;
        }
    }
    a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $gray;
        font-weight: 400;
        border: 1px solid $gray;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        width: 30px;
        height: 30px;
        margin-left: 10px;
        &:not(.previous):not(.next) {
            letter-spacing: 0.125rem;
        }
    }
    .previous, .next {
        border: none;
    }
    .next {
        width: 5px;
        padding-left: 15px;
    }
    .disabled {
        color: $gray;
    }
    .current a {
        background-color: $brand-primary;
        border-color: $brand-primary;
        color: #fff;
        text-decoration: none;
        font-size: 16px;
    }
}

.active_filters {
    background: #dededd;
    padding: $small-space $large-space 0;
    margin-bottom: $medium-space;
    .active-filter-title {
        display: inline;
        margin-right: $small-space;
        font-weight: 600;
    }
    ul {
        display: inline;
    }
    .filter-block {
        @include box-shadow;
        color: $gray-darker;
        margin-right: $small-space;
        margin-bottom: $small-space;
        background: white;
        padding: 0.625rem;
        display: inline-block;
        font-size: $font-size-xs;
        .close {
            color: $gray-darker;
            font-size: $font-size-lg;
            opacity: 1;
            margin-top: 0.1875rem;
            margin-left: 0.3125rem;
        }
    }
}

.block-categories {
    background: white;
    padding: 20px;
    border: 1px solid $gray;
    margin-bottom: 30px;
    .category-top-menu {
        margin-bottom: 0;
        li:first-child {
            border-bottom: 1px solid $brand-primary;
            padding-bottom: 10px;
            margin-bottom: 20px;
            a {
                text-transform: none;
                font: 600 14px $font-family;
                color: $gray-dark;
            }
        }
    }
    .category-sub-menu {
        margin-top: $small-space;
        .category-sub-link {
            font-size: $font-size-sm;
        }
        li {
            position: relative;
            &[data-depth="1"] {
                margin-bottom: $small-space;
            }
            &:first-child {
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 0;
                a {
                    text-transform: none;
                    font: 600 14px $font-family;
                    color: $gray-dark;
                }
            }
            a {
                text-transform: none;
                font: 300 14px $font-family;
                color: $gray-dark;
            }
        }
        li[data-depth="0"] > a {
            width: 100%;
            display: inline-block;
            font: 300 14px $font-family;
            color: $brand-secondary;
            padding-bottom: 10px;
        }
        li:not([data-depth="0"]):not([data-depth="1"]) {
            padding-left: 0.3125rem;
            &::before {
                content: "-";
                margin-right: 0.3125rem;
            }
        }
    }
    a {
        color: $gray-darker;
    }
    .collapse-icons {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        cursor: pointer;
        &[aria-expanded="true"] {
            .add {
                display: none;
            }
            .remove {
                display: block;
            }
        }
        .add,
        .remove {
            &:hover {
                color: $brand-primary;
            }
        }
        .remove {
            display: none;
        }
    }
    .arrows {
        .arrow-right,
        .arrow-down {
            font-size: $font-size-sm;
            cursor: pointer;
            margin-left: 2px;
            &:hover {
                color: $brand-primary;
            }
        }
        .arrow-down {
            display: none;
        }
        &[aria-expanded="true"] {
            .arrow-right {
                display: none;
            }
            .arrow-down {
                display: inline-block;
            }
        }
    }
}

.facets-title {
    color: $gray-darker;
}

.products-selection {
    .filter-button {
        .btn-secondary {
            padding: 0.75rem 0.5rem 0.6875rem;
        }
    }
}

/**custom**/
.categ-name {
    text-transform: none;
    font-size: 27px;
    font-weight: 600;
    color: $gray-dark;
    border-bottom: 1px solid $brand-primary;
    padding-bottom: 23px;
}

.heading-nb-products {
    text-transform: none;
    font-size: 20px;
    font-weight: 600;
}

.unitprice-whithout-tax {
    font-size: 14px;
    font-weight: 400;
    display: block;
    margin-bottom: 10px;
}
.prod-ref-container {
    margin-top: 18px;
    text-align: center;
    padding: 0 5px;
    min-height: 90px;
    max-height: 90px;
    overflow: hidden;
    .green-tax {
        font-size: 12px !important; color: #2d8d26;line-height:14px;
      }
    label, span {
        color: $search-gray;
        font: 300 12px $font-family;
        margin-bottom: 0;
    }
    .man-container {
        display: block;
        height: 20px;
    }
    .empty-space-3 {
        height: 20px;
    }
}

.product_show_list {
    .prod-ref-container {
        @include media-breakpoint-down(lg) {
            margin-top: 4px;
        }
    }
}

.product-title {
    padding-left: 10px;
    padding-right: 10px;
    a {
        font: 700 14px $font-family;
        color: #000;
    }
}

.block-categories {
    .navbar-toggler {
        display: none;
    }
}

#products {
    .product_show_list {
        .add-to-cart-listing {
            text-align: center;
        }
        .list-view-img, .product-description, .list-view-cart {
            display: inline-block;
        }
        .product-description {
            width: 41.66667%;
            margin-top: 25px;
            @include media-breakpoint-up(lg) {
                width: 50%;
            }
        }
        .discount-percentage {
            left: -198px;
            top: 15px;
            @include media-breakpoint-down(lg) {
                left: -152px;
            }
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
        .thumbnail-container {
            border: none;
            border-top: 1px solid $gray;
        }
        .product-thumbnail {
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .list-view-cart {
            margin-top: 35px;
        }
        @include media-breakpoint-up(lg) {
            .add-to-cart-listing {
                font-size: 14px;
            }
            .input-group {
                width: 65px;
            }
            .add-to-cart-listing .bootstrap-touchspin-down {
                left: -91px;
            }
        }
        @include media-breakpoint-down(lg) {
            .discount-percentage {
                display: none;
            }
            .product-description {
                margin-top: 0;
            }
            .list-view-cart {
                margin-top: 15px;
            }
            .product-title {
                margin-bottom: 0;
            }
        }
        @include media-breakpoint-down(md) {
            .product-title {
                min-height: auto;
                margin-bottom: 0;
            }
            .product-description {
                margin-bottom: 0;
            }
            .list-view-cart {
                margin-top: 0;
            }
            .add-to-cart-listing {
                padding: 6px 15px;
                font-size: 10px;
            }
            .product-flags {
                display: none;
            }
        }
    }
}

#category, #search, #module-favoriteproducts17-account, #prices-drop, #manufacturer {
    @include media-breakpoint-up(lg) {
        #content-wrapper {
            width: 76.5%;
        }
        #left-column {
            width: 23.5%;
        }
    }
}

#js-product-list {
    .products {
        margin-right: -10px;
        margin-left: -10px;
    }
}

.reduced-col-padding {
    padding-left: 10px;
    padding-right: 10px;
    //@include media-breakpoint-down(md) {
    //  padding-left: 15px;
    //  padding-right: 15px;
    //}
}

.pagination {
    div {
        color: $gray-dark;
        font: 500 16px $font-family;
        display: inline-block;
        @include media-breakpoint-down(xs) {
            font-size: 14px;
        }
    }
    .spacer {
        margin-left: 10px;
        color: $gray-dark;
        font: 500 16px $font-family;
        @include media-breakpoint-down(xs) {
            margin-left: 0px;
            margin-right: 10px;
        }
    }
    .fa-caret-right, .fa-caret-left {
        color: $gray-dark;
    }
}

#category {
    #content-wrapper {
        @include media-breakpoint-down(xs) {
            h1.h1 {
                margin-bottom: 20px;
            }
        }
    }
}



/**custom**/

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
    #category {
        #left-column {
            width: 100%;
            #search_filters_wrapper {
                margin-left: -30px;
                margin-right: -30px;
            }
            #search_filter_controls {
                text-align: center;
                button {
                    margin: 0 0.5rem;
                }
                margin-bottom: 1rem;
            }
            #search_filters {
                margin-bottom: 0;
                box-shadow: none;
                padding: 0;
                border-top: 1px solid $gray-lighter;
                .facet {
                    .title {
                        cursor: pointer;
                        .collapse-icons .remove {
                            display: none;
                        }
                    }
                    .title[aria-expanded="true"] {
                        .collapse-icons {
                            .add {
                                display: none;
                            }
                            .remove {
                                display: block;
                            }
                        }
                    }
                    padding-top: 0;
                    border-bottom: 1px solid $gray-lighter;
                    .facet-title {
                        color: $gray-darker;
                        text-transform: uppercase;
                    }
                    .h6 {
                        margin-bottom: 0;
                        padding: 0.625rem;
                        display: inline-block;
                    }
                    .navbar-toggler {
                        display: inline-block;
                        padding: 0.625rem 0.625rem 0 0;
                    }
                    .collapse {
                        display: none;
                        &.in {
                            display: block;
                        }
                    }
                    .facet-label {
                        a {
                            margin-top: 0;
                        }
                    }
                    ul {
                        margin-bottom: 0;
                        li {
                            border-top: 1px solid $gray-lighter;
                            padding: 0.625rem;
                        }
                    }
                }
            }
        }
        #content-wrapper {
            width: 100%;
        }
        #search_filter_toggler {
            width: 100%;
        }
    }

    .dropdown-backdrop {
        opacity: 0.5;
        background-color: #000;
    }

    .products-sort-order {
        .select-title {
            margin-left: 0;
        }
    }
    .products-selection {
        h1 {
            padding-top: 0;
            text-align: center;
            margin-bottom: 1rem;
        }
        .showing {
            padding-top: 1rem;
        }
    }
    #prices-drop #content-wrapper,
    #new-products #content-wrapper,
    #best-sales #content-wrapper {
        width: 100%;
    }
}

@include media-breakpoint-down(xs) {
    .products-selection {
        .filter-button {
            padding-left: 0;
        }
    }
    #category {
        #left-column {
            #search_filters_wrapper {
                margin-left: -15px;
                margin-right: -15px;
            }
        }
    }
}
