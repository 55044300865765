#product {
    #content {
        position: relative;
    }
}

.product-price {
    color: $brand-primary;
    display: inline-block;
}

#product-description-short {
    color: $gray-darker;
}

.product-information {
    font-size: $font-size-lg;
    color: $gray-darker;

    .manufacturer-logo {
        height: 35px;
    }

    .product-description img {
        max-width: 100%;
        height: auto;
    }
}

.input-color {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 1.25rem;
    width: 1.25rem;
}

.input-container {
    position: relative;
}

.input-radio {
    position: absolute;
    top: 0;
    cursor: pointer;
    opacity: 0;
    width: 100%;
    height: 100%;
}

.input-color,
.input-radio {
    &:checked + span,
    &:hover + span {
        border: 2px solid $gray-darker;
    }
}

.radio-label {
    @include box-shadow;
    background: white;
    display: inline-block;
    padding: 0.125rem $small-space;
    font-weight: 600;
    border: 2px solid white;
}

.product-actions {
    .control-label {
        width: 100%;
        margin-bottom: 0.375rem;
        display: block;
        width: 100%;
        text-align: center;
        color: $brand-secondary;
    }
    .add-to-cart {
        line-height: inherit;
        display: inline-block;
        border-radius: 17px;
        padding: 7px 25px 7px 58px;
        text-transform: none;
        position: relative;
        font-weight: 400;
        font-size: 15px;
        box-shadow: none;
        .ppg-cart-icon {
            display: inline-block;
            margin-right: 9px;
            height: 36px;
            width: 40px;
            overflow: hidden;
            vertical-align: middle;
            position: absolute;
            left: -1px;
            top: -1px;
            background-color: #cb4d52;
            border-radius: 14px;
        }
        .cart-icon:before {
            content: '';
            margin-top: 10px;
            display: inline-block;
            background-image: url(../img/cart-icon-white.png);
            background-repeat: no-repeat;
            width: 18px;
            height: 14px;
        }
        .material-icons {
            line-height: inherit;
        }
    }
    .product-minimal-quantity {
        margin-bottom: 0;
    }
    .add-remove-favorites {
        ul {
            margin-bottom: 0;
        }
    }
}

.product-quantity {
    display: flex;
    .qty, .add {
        float: left;
        display: inline-flex;
        margin-bottom: 0.5rem;
    }
    .qty {
        margin-right: 0.4rem;
    }
    #quantity_wanted {
        color: $brand-secondary;
        background-color: white;
        height: 30px;
        padding: 0.175rem 0.5rem;
        width: 3rem;
        font-size: 14px;
    }
    .input-group-btn-vertical {
        width: auto;
        .btn {
            padding: 0.5rem 0.6875rem;
            i {
                font-size: 1rem;
                top: 0.125rem;
                left: 0.1875rem;
            }
        }
    }
    .btn-touchspin {
        height: 1.438rem;
    }
}

.product-discounts {
    margin: 10px 0;
    > .product-discounts-title {
        font-weight: normal;
        color: $brand-secondary;
        font: 400 14px $font-family;
        text-align: center;
    }
    > .table-product-discounts {
        color: $brand-primary;
        text-align: center;
        width: 100%;
        border: 1px solid $gray;
        th {
            text-align: center;
        }
        div, span {
            font-size: 14px;
        }
        span {
            display: inline-block;
        }
    }
}

.product-prices {
    margin-top: $medium-space;
    text-align: center;
    .tax-shipping-delivery-label {
        font-size: 0.8125rem;
        color: $gray;
    }
}

.product-discount {
    color: $gray;
    .regular-price {
        text-decoration: line-through;
        font-weight: normal;
        margin-right: $small-space;
        font-size: 12px;
        color: $brand-secondary;
    }
}

.product-price {
    color: $brand-primary;
    font-size: 25px;
    font-weight: 600;
}

.has-discount {
    &.product-price, p {
        color: $brand-primary;
        font-size: 25px;
        font-weight: 600;
    }
    .discount {
        background: $brand-secondary;
        color: white;
        font-weight: 600;
        padding: 0.3125rem $small-space;
        font-size: $font-size-base;
        margin-left: $small-space;
        text-transform: uppercase;
        display: none;
    }
}

.product-unit-price {
    font-size: $font-size-xs;
    margin-bottom: 0;
}

.tabs {
    //@include box-shadow;
    margin-top: 2rem;
    background: white;
    padding: 0;
    .tab-pane {
        padding-top: $medium-space;
    }
    .nav-tabs {
        border: none;
        border-bottom: $gray-light 2px solid;
        .nav-link {
            color: $gray;
            border: 0 solid transparent;
            font: 500 20px $font-family;
            padding: 0 50px 20px 0;
            &.active {
                border: none;
                border-bottom: $brand-primary 2px solid;
                color: $brand-secondary;
            }
            &:hover {
                border: none;
                border-bottom: $brand-primary 2px solid;
            }
        }
        .nav-item {
            float: left;
            margin-bottom: -0.125rem;
        }
    }
}

.product-cover {
    margin-bottom: $medium-space;
    position: relative;
    img {
        background: white;
    }
    .layer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: white;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        background: rgba(255, 255, 255, 0.7);
        text-align: center;
        cursor: pointer;
        @include transition(opacity .7s ease-in-out);
        &:hover {
            opacity: 1;
        }
        .zoom-in {
            font-size: 6.25rem;
            color: $gray;
        }
    }
}

.images-container {
    overflow: hidden;
    max-height: 481px;
    padding-top: 25px;
}

#product-modal {
    .modal-content {
        background: transparent;
        border: none;
        padding: 0;
        .modal-body {
            display: flex;
            margin-left: -30%;
            .product-cover-modal {
                background: white;
            }
            .image-caption {
                background: white;
                width: 800px;
                padding: 0.625rem 1.25rem;
                border-top: $gray-light 1px solid;
                p {
                    margin-bottom: 0;
                }
            }
            .thumbnails {
                position: relative;
            }
            .mask {
                position: relative;
                overflow: hidden;
                max-height: 49.38rem;
                margin-top: 2.188rem;
                z-index: 1;
                &.nomargin {
                    margin-top: 0;
                }
            }
            .product-images {
                margin-left: $extra-large-space;
                img {
                    width: 9.25rem;
                    cursor: pointer;
                    background: white;
                    &:hover {
                        border: $brand-primary 3px solid;
                    }
                }
            }
            .arrows {
                height: 100%;
                width: 100%;
                text-align: center;
                position: absolute;
                top: 0;
                color: white;
                right: $large-space;
                z-index: 0;
                .arrow-up {
                    position: absolute;
                    top: -2rem;
                    opacity: 0.2;
                }
                .arrow-down {
                    position: absolute;
                    bottom: -3rem;
                }
                i {
                    font-size: 6.25rem;
                    display: inline;
                }
                cursor: pointer;
            }
        }
    }
}

#blockcart-modal {
    color: $gray-darker;
    .modal-header {
        background: $brand-primary;
        padding: 10px;
    }
    .modal-body {
        background: #fff;
        // padding: 3.125rem 1.875rem;
        .divide-right span, .modal-prod-quantity {
            display: inline-block;
            margin-bottom: 0.3125rem;
            font: 400 16px $font-family;
            color: #000;
        }
    }
    .modal-dialog {
        max-width: 1140px;
        width: 100%;
    }
    .product-image {
        width: 100%;
        margin: 0;
    }
    .modal-title {
        color: white;
        font: 400 18px $font-family;
        // text-align: center;
        margin-top: 4px;
        i.material-icons {
            margin-right: 0.875rem;
        }
    }
    .modal-total {
        font: 400 16px $font-family;
        color: $gray-dark;
    }
    .product-name {
        margin-bottom: 0;
        color: $gray-dark;
        font: 600 16px $font-family;
    }
    .modal-prod-price {
        font: 400 18px $font-family;
        color: $gray-dark;
        margin-bottom: 0;
    }
    p.regular-price {
        text-decoration: line-through;
        font-size: 18px;
        color: $gray-dark;
    }
    .modal-header .close {
        margin-top: 4px;
    }
    .cart-content {
        float: right;
        .cart-content-btn {
            display: inline-flex;
            button {
                margin-right: 0.3125rem;
            }
            .btn {
                font: 400 14px $font-family;
                white-space: inherit;
                box-shadow: none;
                border-radius: 5px;
                padding: 10px;
            }
            .end-order {
                background-color: $brand-primary;
                margin-bottom: 0;
                &:hover {
                    background-color: darken($brand-primary, 10%);
                }
            }
        }
        p {
            font: 400 16px $font-family;
        }
        .cart-products-count {
            font: 700 16px $font-family;
        }
    }
}

.modal-cell {
    vertical-align: middle;
    display: table-cell;
    float: none;
    p {
        margin-bottom: 0;
    }
}

.product-images {
    > li.thumb-container {
        display: inline;
        > .thumb {
            @include box-shadow;
            cursor: pointer;
            margin-bottom: $small-space;
            .images-container & {
                margin-right: 0.8125rem;
            }
            &.selected,
            &:hover {
                border: $brand-primary 3px solid;
            }
        }
    }
}

#main {
    .images-container {
        .js-qv-mask {
            white-space: nowrap;
            overflow: hidden;
            &.scroll {
                width: calc(100% - 60px);
                margin: 0 auto;
            }
        }
    }
}

.scroll-box-arrows {
    display: none;
    &.scroll {
        display: block;
    }
    i {
        position: absolute;
        bottom: 1.625rem;
        height: 100px;
        line-height: 100px;
        cursor: pointer;
    }
    .left {
        left: 0;
    }
    .right {
        right: 0;
    }
}

#product-availability {
    margin-top: 0.625rem;
    display: inline-block;
    font-weight: 700;
    .material-icons {
        line-height: inherit;
    }
    .product-available {
        color: $brand-success;
    }
    .product-unavailable {
        color: $brand-warning;
    }
    .product-last-items {
        color: $brand-warning;
    }
}

#product-details {
    .label {
        font-size: $font-size-base;
        color: $gray-darker;
        font-weight: bold;
    }
}

.product-features {
    margin-top: $medium-space;
    .h6 {
        font: 800 14px $font-family;
        text-transform: uppercase;
        color: $brand-secondary;
        padding-bottom: 25px;
        margin-bottom: 0;
    }
    > dl.data-sheet {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        dd.value,
        dt.name {
            flex: 1 0 60%;
            display: flex;
            justify-content: left;
            align-items: center;
            font-weight: normal;
            background: $gray-lighter;
            padding: 20px;
            margin-right: 0;
            min-height: 3.5rem;
            @include media-breakpoint-down(sm) {
                min-height: 4.5rem;
            }
            word-break: normal;
            margin-bottom: 0;
            font: 800 14px $font-family;
            color: $brand-secondary;
            text-overflow: ellipsis;
            overflow: hidden;
            @include media-breakpoint-down(sm) {
                padding: 5px;
                flex: 1 0 50%;
            }
            &:nth-of-type(even) {
                background: #fff;
            }
        }
        dt.name {
            text-transform: capitalize;
            flex: 1 0 27%;
            margin-right: 0;
            font: 400 14px $font-family;
            color: $gray-dark;
            @include media-breakpoint-down(sm) {
                padding: 5px;
                flex: 1 0 50%;
            }
        }
    }
}

.product-variants {
    ul {
        text-align: center;
        display: inline-block;
        margin-bottom: 0;
        li {
            margin: 0 5px;
        }
    }
    > .product-variants-item {
        margin: 10px 0 0 0;
        text-align: center;
        display: inline-block;
        width: 100%;
        select {
            background-color: white;
            display: inline-block;
            width: auto;
            padding-right: 1.875rem;
        }
        ul li {
            margin-right: 0;
        }
        .color {
            margin-left: 0;
            margin-top: 0;
        }
    }
}

.product-flags {
    position: absolute;
    top: 0;
    width: 80%;
    display: flex;
    flex-flow: column wrap;
}

li.product-flag, .discount-flag {
    display: block;
    width: 84px;
    text-align: center;
    left: 115px;
    @media (max-width: 768px) {
        left: 0;
    }
    position: relative;
    background: $brand-secondary;
    font: 300 14px $font-family;
    padding: 10px 21px;
    text-transform: uppercase;
    color: white;
    margin-top: $small-space;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 2;
    &.online-only {
        top: 25rem;
        right: 0;
        font-size: $font-size-xs;
        &::before {
            content: "\E30A";
            font-family: 'Material Icons';
            vertical-align: middle;
            margin: 0.3125rem;
        }
    }
    &.discount {
        display: none;
    }
    &.on-sale {
        background: $brand-secondary;
        width: 100%;
        text-align: center;
        margin: 0;
        left: 0;
        top: 0;
    }
    &:not(.discount):not(.on-sale) ~ li.product-flag {
        margin-top: 3.75rem;
    }
    &.new {
        order: 1;
    }
    &.discount-percentage {
        background-color: $brand-primary;
        top: 0;
        padding: 10px 27px;
        order: 2;
    }
    &.discount-amount {
        background-color: $brand-primary;
        top: 0;
        padding: 10px;
        order: 3;
    }
    &.pack {
        display: none;
    }
}

.product-customization {
    margin: $extra-large-space 0;
    .product-customization-item {
        margin: $medium-space 0;
    }
    .product-message {
        background: $gray-light;
        border: none;
        width: 100%;
        height: 3.125rem;
        resize: none;
        padding: 0.625rem;
        &:focus {
            background-color: white;
            outline: 0.1875rem solid $brand-primary;
        }
    }
    .file-input {
        width: 100%;
        opacity: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;
        height: 2.625rem;
        overflow: hidden;
        position: absolute;
    }

    .customization-message {
        margin-top: 20px;
    }
    .custom-file {
        position: relative;
        background: $gray-light;
        width: 100%;
        height: 2.625rem;
        line-height: 2.625rem;
        text-indent: 0.625rem;
        display: block;
        color: $gray;
        margin-top: $medium-space;
        button {
            z-index: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    small {
        color: $gray;
    }
}

.product-pack {
    margin-top: $extra-large-space;
    .pack-product-container {
        display: flex;
        justify-content: space-around;
        .pack-product-name {
            flex: 0 0 50%;
            font-size: 0.875rem;
            color: $gray;
        }
        .pack-product-quantity {
            border-left: $gray-light 2px solid;
            padding-left: $small-space;
        }
        .pack-product-name,
        .pack-product-price,
        .pack-product-quantity {
            display: flex;
            align-items: center;
        }
    }
}

.product-refresh {
    margin-top: $medium-space;
}

.social-sharing {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    ul {
        margin-bottom: 0;
    }
    li {
        @include box-shadow-light;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        display: inline-block;
        background-color: white;
        background-repeat: no-repeat;
        background-size: 2rem 2rem;
        background-position: 0.25rem 0.25rem;
        cursor: pointer;
        margin-left: 0.5rem;
        @include transition(all .2s ease-in);
        a {
            display: block;
            width: 100%;
            height: 100%;
            white-space: nowrap;
            text-indent: 100%;
            overflow: hidden;
            &:hover {
                color: transparent;
            }
        }
    }
}

.products-selection {
    margin-bottom: $medium-space;
    .title {
        color: $gray;
    }
}

#blockcart-modal .cart-content {
    .btn {
        margin-bottom: $small-space;
    }
}

//**Product Page**//
.prod-imgs-slider {
    padding-right: 0;
    margin-top: 30px;
    @media (min-width: 768px) {
        margin-top: 0;
    }
    li {
        padding-bottom: 7px;
        padding-top: 7px;
        img {
            width: 80px;
            height: 80px;
            border: 1px solid $gray;
        }

    }
    li.slick-current {
        img {
            border-bottom: 2px solid $brand-primary;
        }
    }
    .slick-next {
        left: 25px;
        top: -15px;
        width: 33px;
        //transform: rotate(270deg);
    }
    .slick-prev {
        bottom: -50px;
        top: auto;
        left: 25px;
        width: 33px;
        //transform: rotate(270deg);
    }
    .slick-next:before {
        content: '' !important;
        background-image: url('../img/next-product-arrow.png');
        width: 33px;
        height: 30px;
        background-repeat: no-repeat;
        display: block;
    }

    .slick-prev:before {
        content: '' !important;
        background-image: url('../img/prev-product-arrow.png');
        width: 33px;
        height: 30px;
        background-repeat: no-repeat;
        display: block;
    }
}

.ppg-product-title {
    font: 400 25px $font-family;
    color: $brand-secondary;
    text-transform: none;
    border-bottom: 1px solid $gray;
    padding-bottom: 15px;
    margin-bottom: 35px;
}

.container-1 {
    border-bottom: 1px solid $gray;
    padding-bottom: 15px;
    @media (min-width: 576px) and (max-width: 992px) {
        margin-top: 15px;
    }
    label, span, a {
        color: $brand-secondary;
        font-size: 14px;
        padding-bottom: 10px;
        margin-bottom: 0;
    }
}

.container-2 {
    margin-top: 20px;
    @media (min-width: 576px) and (max-width: 992px) {
        margin-top: 30px;
    }
    a {
        color: $brand-secondary;
        font: 400 14px $font-family;
        &:focus, &:active {
            color: $brand-primary;
        }
    }
}

.container-3 p {
    display: inline-block;
    width: 100%;
    color: $brand-success;
    margin-bottom: 10px;
    font-size: 14px;
}

.attachment {
    a {
        &:before {
            display: inline-block;
            content: '';
            background-image: url("../img/down-arrow-black.png");
            background-repeat: no-repeat;
            width: 10px;
            height: 11px;
            padding-right: 15px;
        }
        &:active, &:focus {
            &:before {
                background-image: url('../img/down-arrow-red.png');
            }
        }
    }
}

.ppg-right-container {
    border: 1px solid $gray;
    padding: 30px 20px;
    @media (max-width: 576px) {
        margin-top: 15px;
    }
    .product-quantities {
        text-align: center;
        padding: 0 0 20px 0;
        border-bottom: 1px solid $gray;
        .prod-avail {
            color: $brand-secondary;
            font: 400 14px $font-family;
        }
        .product-stock {
            font: 600 14px $font-family;
            color: $brand-success;
        }
        .out-of-stock {
            color: $brand-primary;
        }
        .limited-stock {
            color: $brand-warning;
        }
    }
    .product-add-to-cart {
        .product-quantity {
            display: inline-block;
            width: 100%;
        }
        .input-group bootstrap-touchspin {
            float: none;
        }
        .add {
            display: block;
            text-align: center;
            width: 100%;
        }
        .qty {
            display: block;
            float: none;
            width: 100%;
            text-align: center;
        }
        input {
            text-align: center;
            font-size: 13px;
            color: $gray;
        }
        .input-group bootstrap-touchspin {
            float: none;
            position: relative;
        }
        .bootstrap-touchspin {
            float: none;
        }
        .bootstrap-touchspin .input-group-btn-vertical > .btn {
            position: absolute;
            width: 30px;
            height: 30px;
            padding: 8px 13px;
        }
        .bootstrap-touchspin-up {
            left: 0;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        .bootstrap-touchspin-down {
            left: -74px;
            top: 2px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        .bootstrap-touchspin input.form-control, .bootstrap-touchspin input.input-group {
            height: 27px;
            width: 48px;
        }
        .bootstrap-touchspin .input-group-btn-vertical i {
            top: 3px;
            left: 5px;
            font-size: 15px;
        }
        .bootstrap-touchspin .input-group-btn-vertical .touchspin-up:after {
            content: '\f067';
            font-family: $font-awesome;
            font-weight: 900;
            font-size: 8px;
            color: $gray;
        }
        .bootstrap-touchspin .input-group-btn-vertical .touchspin-down:after {
            content: '\f068';
            font-family: $font-awesome;
            font-weight: 900;
            font-size: 8px;
            color: $gray;
        }
    }
}

.similar-container, .product-accessories-wrapper, .modrp-resealed-products {
    background-color: $gray-light;
    //margin-top: 30px;
    padding: 40px 0 0 0;
    .reduced-col-padding {
        padding-left: 15px;
        padding-right: 15px;
    }
    .productsliders-top {
        border-bottom: 2px solid $gray;
        margin: 0 15px 40px 15px;
        h3, .h5 {
            margin: 0;
            margin-bottom: -2px;
            border: none;
            border-bottom: $brand-primary 2px solid;
            color: $brand-secondary;
            display: inline-block;
            font: 500 20px $font-family;
            padding: 0 50px 20px 0;
            text-transform: none;
        }
    }
}

.product-cover {
    @include media-breakpoint-up(lg) {
        margin-left: -15px;
    }
}

//**Product Page**//

@include media-breakpoint-down(md) {
    .product-cover {
        img {
            width: 100%;
        }
    }
    #product-modal .modal-content .modal-body {
        flex-direction: column;
        margin-left: 0;
        img.product-cover-modal {
            width: 100%;
        }
        .arrows {
            display: none;
        }
    }
    #product-modal .modal-content .modal-body .image-caption {
        width: 100%;
    }
    #blockcart-modal {
        .modal-dialog {
            width: calc(100% - 20px);
        }
        .modal-body {
            padding: 1.875rem;
        }
    }
}

@include media-breakpoint-down(sm) {
    #blockcart-modal {
        .divide-right {
            border-right: none;
        }
        .modal-body {
            padding: 1rem;
        }
    }
    #blockcart-modal .cart-content {
        float: none;
    }
    .modal-cell {
        vertical-align: middle;
        display: block;
        float: none;
        margin-bottom: 10px;
        text-align: center;
    }
}
