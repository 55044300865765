.pr-0 {
    padding-right: 0;
}
.img-fluid {
    margin-top: 10px;
}

#module-asmmanager-manager, #module-asmmanager-account, #module-asmmanager-auth {
    @media (max-width: 768px) {
        .col-md-9 {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
    @media (max-width: 576px) {
        .col-md-9 {
            max-width: 100%;
            flex: 0 0 100%;
        }
        .btn-logout {
            width: 50px !important;
            font-size: 17px !important;
            position: fixed;
            top: 20px;
            right: 0;
            z-index: 997;
        }
        .partner_title{
            .btn-default {
                width: auto !important;
                padding: 10px 15px !important;
                margin-top: 0;
            }
        }
    }
    .border-rl-none {
        border-left: transparent;
        border-right: transparent;
    }
    .border-left-none {
        border-left: transparent;
    }
    .line-products, .line-shipping, .line-total {
        .footable-toggle {
            display: none;
        }
    }
}