.cart-grid {
  margin-bottom: 1rem;
}
.cart-items {
  margin-bottom: 0;
}
.cart-item {
  padding: 1rem 0;
}
.cart-summary-line {
  @include clearfix;
  clear: both;
  .label {
    padding-left: 0;
    font-weight: normal;
    white-space: inherit;
  }
  .value {
    color: $gray-darker;
    float: right;
  }
  &.cart-summary-subtotals {
    .label,
    .value {
      font-weight: normal;
    }
  }
}
/** CART BODY **/
.cart-grid-body {
  a.label {
    &:hover {
      color: $brand-primary;
    }
  }
  .card-block {
    padding: 1rem;
    h1 {
      margin-bottom: 0;
    }
  }
  .cart-overview {
    padding: 1rem;
  }
  margin-bottom: 0.75rem;
}
/** CART RIGHT **/
.cart-grid-right {
  .promo-discounts {
    margin-bottom: 0;
    .cart-summary-line {
      .label {
        color: $gray;
        .code {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
.block-promo {
  .promo-code {
    padding: 1.60rem;
    background: $gray-light;
    .alert-danger {
      position: relative;
      margin-top: 1.25rem;
      background: $brand-danger;
      color: white;
      display: none;
      &::after {
        bottom: 100%;
        left: 10%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color:  $brand-danger;
        border-width: 10px;
        margin-left: -10px;
      }
    }
  }
  .promo-input {
    color: $gray-darker;
    border: $gray 1px solid;
    height: 2.5rem;
    text-indent: 0.625rem;
    width: 60%;
    + button {
      margin-top: -4px;
      text-transform: capitalize;
    }
  }
  .cart-summary-line .label,
  .promo-name {
    color: $brand-warning;
    font-weight: 600;
    a {
      font-weight: normal;
      color: $gray-darker;
      display: inline-block;
    }
  }
  .promo-code-button {
    padding-left: 1.25rem;
    margin-bottom: 1.25rem;
    display: inline-block;
  }
  &.promo-highlighted {
    padding: 1.25rem;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
/** CONTENT LEFT **/
.product-line-grid-left {
  img {
    max-width: 100%;
  }
}
/** CONTENT BODY **/
.product-line-grid-body {
  > .product-line-info {
    > .label {
      padding: 0;
      line-height: inherit;
      text-align: left;
      white-space: inherit;
    }
    > .out-of-stock {
      color: red;
    }
    > .available {
      color: $brand-success;
    }
    > .unit-price-cart {
      padding-left: 0.3125rem;
      font-size: 0.875rem;
      color: $gray;
    }
  }
}
/** CONTENT LEFT **/
.product-line-grid-right {
  .cart-line-product-actions,
  .product-price {
    color: $brand-secondary;
    line-height: 36px;
    font: 500 18px $font-family;
    .remove-from-cart {
      color: $gray-darker;
      display: inline-block;
      margin-top: 0.3125rem;
    }
  }
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  .product-line-grid-body {
    margin-bottom: 1rem;
  }
}

@include media-breakpoint-down(xs) {
  .cart-items {
    padding: 1rem 0;
  }
  .cart-item {
    border-bottom: 1px solid $gray-light;
    &:last-child {
      border-bottom: 0;
    }
  }
  .cart-grid-body {
    .cart-overview {
      padding: 0;
    }
    .no-items {
      padding: 1rem;
      display: inline-block;
    }
  }
  .product-line-grid-left {
    padding-right: 0 !important;
  }
}

@media (max-width: 360px) {
  .product-line-grid-right {
    .qty {
      width: 100%;
    }
    .price {
      width: 100%;
    }
  }
}

.product-line-grid .green-tax {
  font-size: 12px !important; color: #2d8d26;
}
