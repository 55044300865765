//hamburger menu
#header {
  .mainmenu {
    .menu {
      z-index: 9;
      .hb-bar {
        display: none;
      }
      .menuToggle > input[type="checkbox"] {
        display: none;
      }
      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
        position: relative;
        .menuToggle > input[type="checkbox"] {
          display: block;
        }
        .menuToggle > ul.menu > li.category:first-child > a {
          display: none;
        }
        .top-menu {
          display: block;
          width: 100% !important;
          a:after {
            display: none!important;
          }
        }
        .hb-bar {
          color: #2b2a29;
        }
        .menuToggle {
          display: block;
          position: relative;
          top: 0;
          left: 0;
          -webkit-user-select: none;
          user-select: none;
          z-index: 9996;
          cursor: pointer;
          > input {
            display: block;
            width: 50px;
            height: 37px;
            position: fixed;
            left: 5px;
            top: 6px;
            cursor: pointer;
            opacity: 0; /* hide this */
            z-index: 9999; /* and place it over the hamburger */
            -webkit-touch-callout: none;
          }

          > span {
            display: block;
            opacity: 1;
            position: fixed;
            top: 20px;
            left: 20px;
            width: 33px;
            height: 4px;
            margin-bottom: 5px;
            // background: #2980b9;
            border-radius: 3px;
            z-index: 9998;
            transform-origin: 4px 0;
            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
            opacity 0.55s ease,
            left 0.55s ease,
            top 0.55s ease,
            display 0.55s ease;

            &:first-child {
              top: 20px;
              transform-origin: 0% 10%;
            }
            &:nth-last-child(2) {
              //transform-origin: 0 100%;
            }

          }
          > input:checked {
            width: 40px;
            height: 40px;
          }
          > input:checked ~ span {
            display: block;
            opacity: 1;
            transform: rotate(45deg) translate(-2px, -1px);
            background: #2b2a29;
            position: fixed;
            top: 30px;
            left: 15px;
          }
          > input:checked ~ span:nth-last-child(3) {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
          }
          > input:checked ~ span:nth-child(2) {
            transform: rotate(-45deg) translate(0, -1px);
            top: 47px;
            left: 12px;
          }

          > ul.menu {
            position: fixed;
            display: block;
            width: 100%;
            top: 58px;
            left: 0;
            bottom: 0;
            padding: 0 0 58px 0;
            background: #f7f7f7;
            list-style-type: none;
            -webkit-font-smoothing: antialiased;
            /* to stop flickering of text in safari */
            transform-origin: 0% 0%;
            transform: translate(-120%, 0);
            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
            height: 100%;
            min-height: 500px;
            overflow: hidden;
            overflow-y: auto;
            z-index: 9997;
            li.category {
              position: relative;
              background-color: transparent;
              display: block;
              width: 100%;
              float: none;
              padding-left: 0;
              padding-bottom: 0;
              text-align: left;
              a {
                //width: 100%;
              }
              .sub-menu {
                display: block;
                width: 100%;
                float: none;
                min-width: 0;
                top: 0;
                left: 0;
                box-shadow: none;
                position: relative;
                background-color: transparent;
                margin-left: 0;
                .sub-menu2 {
                  display: block;
                  position: relative;
                  left: 0;
                  padding-left: 25px;
                  width: 100%;
                  background-color: #fff;
                  ul.top-menu {
                    background-color: #fff;
                  }

                }
              }
            }
            li.cms-page, li.link {
              text-align: left;
              width: 100%;
            }
            //> li.cms-page:first-of-type {
            //    margin-top: 10px;
            //}
          }
        }
        /*
         * And let's slide it in from the left
         */
        .menuToggle > input:checked ~ ul.menu {
          transform: none;
        }
      }
    }
    li.category {
      .expand {
        display: block;
        display: block;
        position: absolute;
        right: 0;
        top: 0px;
        width: 50px;
        height: 35px;
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        z-index: 999;
        i {
          font-family: $font-awesome;
        }
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }
  }
  .no-icon {
    display: none;
  }
  .no-icon-logo {
    margin-left: 32px;
  }

  // hamburger menu

  // meniu mobile
  @include media-breakpoint-down(sm) {
    div.menu {
      .top-menu {
        li.category {
          img {
            display: none;
          }
        }
      }
    }
    div.sub-menu {
      .top-menu {
        width: 100%;
        //margin-bottom: 15px;
        li.category {
          img {
            display: inline-block;
          }
          a {
            padding-left: 2px;
            margin-right: 40px;
          }
        }
      }
    }
    div.sub-menu2 {
      overflow: hidden;
      .top-menu {
        li.category {
          div.collapse2 {
            position: relative;
            left: 10px;
            background-color: transparent;
            box-shadow: none;
            display: none;
            width: 95%;
            .expand {
              right: -6px;
            }
            div.collapse2 {
              left: 10px;
              .expand {
                right: -6px;
              }
            }
          }
        }
      }
    }
    .mainmenu .menu .menuToggle > ul.menu > li.category:first-child > a {
      display: none;
    }
    div.menu .sub-menu2 ul.top-menu {
      display: block !important;
    }
    div.menu div.sub-menu .top-menu {
      padding: 10px 0!important;
    }
    li.link, li.cms-page {
      padding-left: 20px;
      padding-bottom: 5px;
      font-size: 14px;
    }
  }
}

// meniu mobile