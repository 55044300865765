#advanced_layered_navigation_filter {
  .filters-title {
    text-transform: uppercase;
    font: 600 14px $font-family;
    color: $gray;
    padding-bottom: 10px;
    border-bottom: 1px solid $brand-primary;
  }
  .af_subtitle_heading {
    display: inline-block;
    width: 100%;
    .af_subtitle {
      text-transform: none;
      font: 600 14px $font-family;
      color: $brand-secondary;
      border-bottom: 1px solid $brand-primary;
      &:before {
        display: none;
      }
    }
  }
  .af_filter_content {
    margin-top: 10px;
  }
  .af_filter {
    padding: 20px;
    @include media-breakpoint-down(md) {
      padding: 10px;
    }
    border-bottom: none;
    border: 1px solid $gray;
    margin-bottom: 30px;
    .name {
      font: 400 14px $font-family;
      @include media-breakpoint-down(md) {
        font-size: 12px;
      }
    }
    .count {
      font: 400 13px $font-family;
      color: $gray;
    }
    label {
      margin-bottom: 0;
    }
  }
  .selectedFilters {
    padding-left: 0;
    padding-right: 0;
    .clearAll {
      background-color: transparent;
      padding: 0;
      margin-top: 10px;
      margin-bottom: 10px;
      a {
        position: relative;
        background-color: $gray-light;
        color: #fff;
        color: #000;
        font: 400 13px $font-family;
        padding: 5px;
        padding-left: 32px;
        width: 100%;
        &:hover {
          background-color: lighten(#545454, 10%);
        }
        i {
          font-size: 14px;
          position: absolute;
          left: 5px;
          top: 2px;
          @include media-breakpoint-down(sm) {
            left: 5px;
            top: 6px;
          }
        }
      }
    }
    a {
      display: inline-block;
      padding: 0;
      i {
        font-size: 15px;
      }
    }
    > div {
      padding: 5px;
    }
  }
  .af_filter.type-1 .af_filter_content, .af_filter.type-2 .af_filter_content {
    max-height: 290px;
  }

}

.advanced_layered_navigation_filter{
  z-index: 9;
  &.filterToggle:not(.opened) > input[type="checkbox"] {
    display: none;
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
    position: relative;
    &.filterToggle > input[type="checkbox"] {
      display: block;
    }
    .block_content {
      display: block;
    }
    &.filterToggle {
      display: block;
      position: relative;
      top: 0;
      left: 0;
      -webkit-user-select: none;
      user-select: none;
      z-index: 9996;
      cursor: pointer;
      > input {
        display: block;
        width: 160px;
        height: 37px;
        position: absolute;
        right: 5px;
        top: -37px;
        cursor: pointer;
        opacity: 0;
        z-index: 9999;
        -webkit-touch-callout: none;
      }
      > input[checked="checked"] {
        position: fixed;
        top: 15px;
        right: 13px;
        width: 167px;
        height: 40px;
      }
      .filters_content {
        position: fixed;
        display: block;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        padding: 20px 15px 15px 15px;
        background: #f7f7f7;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        transform-origin: 0% 0%;
        transform: translate(-120%, 0);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
        height: 100%;
        min-height: 500px;
        overflow: hidden;
        overflow-y: auto;
        z-index: 9997;

      }
    }
    /*
     * And let's slide it in from the left
     */
    &.filterToggle.opened .filters_content {
      transform: none;
    }
  }
}

.trigger-filter {
  font: 400 14px $font-family;
  padding: 5px !important;
  border: none;
  background-color: transparent;
  border: 1px solid $gray;
  text-align: center;
  color: $gray-darker;
  text-transform: none;
  width: 100%;
  cursor: pointer;
  box-shadow: none;
  border-radius: 3px;
}

.selectedFilters > div  {
  background: #eee;
  padding: 5px 20px 5px 10px;
  border-radius: 3px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  font: 400 14px $font-family;
  color: #000;
}

.selectedFilters .clearAll {
  padding: 5px 5px 5px 5px;
  > a {
    position: static;
    padding: 0;
    color: #000;
  }
}

.selectedFilters a {
  color: #000;
  opacity: 1;
  i {
    color: #000;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 12px;
    font-weight: 900;
  }
}

@include media-breakpoint-up(sm){
  .selectedFilters .clearAll i {
    float: right;
    padding-top: 5px;
    padding-right: 14px;
  }
  #advanced_layered_navigation_filter {
    .block_content {
      display: inline-block;
      width: 100%;
    }
    .clearAll, .cf {
      z-index: 9;
      margin: 0 20px;
    }
    .clearAll {
      margin-top: 0;
      margin-bottom: 0;
    }
    .cf {
      a {
        right: 5px;
        top: 5px;
        &:before {
          display: none;
        }
      }
    }
  }
}


@include media-breakpoint-down(sm) {
  .show-prods, .prods-counter {
    display: inline-block;
    width: 49%;
    color: $gray-dark;
  }
  .show-prods {
    text-align: center;
    background-color: $brand-primary;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
  }
  .selectedFilters {
    //padding: 0 5px 0 15px;
    overflow-x: scroll;
    white-space: nowrap;
    line-height: 45px;
    display: inline-block;
    width: 100%;
    div {
      display: inline;
      background-color: transparent;
      padding: 0;
      margin-right: 10px;
      &.hidden {
        display: none;
      }
    }
  }
  .advanced_layered_navigation_filter .af_filter .name {
    font-size: 14px;
  }
  .advanced_layered_navigation_filter .selectedFilters .clearAll {
    background-color: transparent;
    margin-top: 0;
    padding-top: 0;
    a {
      background-color: $gray-light;
      color: #000;
      border-radius: 3px;
      padding: 5px;
      padding-left: 30px;
    }
  }
  .advanced_layered_navigation_filter .selectedFilters a i {
    font-size: 14px;
  }
  .sort-by-row {
    display: inline-block;
    width: 100%;
  }
  .selectedFilters {
    .cf {
      a {
        top: 5px;
        right: 1px;
        &:before {
          display: none;
        }
      }
    }
  }
  #js-product-list-top {
    .selectedFilters {
      .cf {
        vertical-align: 0px;
        a {
          display: none;
          top: -2px;
          right: -10px;

        }
      }
    }
  }

  #advanced_layered_navigation_filter {
    .compact_block_content {
      padding: 10px 0px 0px 1px;
    }
    .selectedFilters > div {
      background: transparent;
      border: 1px solid $gray;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      &.cf {
        padding: 5px 25px 5px 5px;
      }
    }
  }

  #advanced_layered_navigation_filter .selectedFilters .clearAll {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    a {
      padding: 4px 4px 5px 25px;
      background-color: transparent;
      color: #000;
      font-size: 15px;
      i {
        left: 1px;
        top: 7px;
      }
    }
  }
  .selectedFilters {
    padding-right: 0;
    padding-left: 0;
  }
}