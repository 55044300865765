  #_desktop_user_info {
    .account-info {
      margin-right:4px;
    }
    a.account {
      padding-bottom: 20px;
    }
    #account-expanded {
      opacity: 1;
      position: absolute;
      left: -55px;
      top: 35px;
      padding-top: 10px;
      @include box-shadow2;
      text-align: left;
      min-width: 220px;
      background: #fff;
      z-index: 100 !important;
      letter-spacing:0;
  
      .account-top {
        display: inline-block;
        width: 92%;
        margin: 0 10px 10px;
        padding-bottom: 10px;
        text-transform: capitalize;
        color: #777777;
        border-bottom: 1px solid #ccc;
        .account-name {
            font: 600 14px $font-family;
            color: #777777;
        }
      }
  
      .login-buttons a {
        border-left:2px solid $brand-primary;
        padding-left:10px;
        margin-bottom:5px;
        display:block;
      }
  
      .account-middle {
        padding: 5px;
        padding-left: 10px;
        a {
          font: 400 13px $font-family;
          color: #777777;
          &:hover {
            color: $brand-primary;
          }
        }
        .account-links-1 i{
          display: inline-block;

          &:before {
            content: "\f0cb";
            font: 900 13px $font-awesome;
            margin-right: 5px;
          }
        }
        .account-links-2 i{
          display: inline-block;
          &:before {
            content: "\f1ad";
            font: 900 13px $font-awesome;
            margin-right: 5px;
          }
        }
        .account-links-3 i{
          display: inline-block;
          margin-right: 0;
          font-size: 13px;
          &:before {
            content: "\f007";
            font: 900 13px $font-awesome;
            margin-right: 5px;
          }
        }
        
        
      }
  
      .account-bottom {
        margin-top: 5px;
        display:flex;
        justify-content:flex-end;
        margin-bottom: 10px;
        a.btn {
          @include button;
          i {
            font-weight: 600;
            padding: 3px 7px 1px 7px;;
            // border-left: 2px solid $brand-primary-d10;
            margin-left: 7px;
            float: right;
          }
        }
      }
    }
  }