@mixin search-box {
  form {
    position: relative;
    input[type=text] {
      &:focus {
        outline: 3px $brand-primary solid;
        color: $gray-darker;
        background: white;
        + button .search {
          color: $brand-primary;
        }
      }
      border: none;
      padding: 10px;
      min-width: 255px;
      color: $gray;
      border: $input-btn-border-width solid $input-border-color;
    }
    button[type=submit] {
      position: absolute;
      background: none;
      border: none;
      bottom: 0.3125rem;
      right: 0.125rem;
      color: $gray;
      .search:hover {
        color: $brand-primary;
      }
    }
  }
}

@mixin box-shadow {
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
}
@mixin box-shadow2 {
  box-shadow: 0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);
}

@mixin box-shadow-light {
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
}

@mixin button {
  background-color: $brand-primary;
  color: #fff !important;
  text-transform:uppercase;
  float: right;
  padding: 5px 10px;
  margin-right: 10px;
  box-shadow: none;
  border: none;
  overflow: hidden;
  font: 400 13px $font-family;
  bottom: 15px;
  display: inline-block;
}