.block_newsletter {
  @include search-box;
  font-size: $font-size-sm;
  p {
    padding-top: $small-space;
  }
  form {
    .input-wrapper {
      overflow: hidden;
    }
    input[type=text] {
      padding: 11px;
      &:focus {
        border: 3px $brand-primary solid;
        padding: 8px 8px 9px;
        outline: 0;
      }
      width: 100%;
    }
    input {
      height: 42px;
      box-shadow: none;
    }
  }
  margin-bottom: 0.625rem;
}
.block-contact, .block-contact-depo {
  color: $brand-secondary;
  font-size: 12px;
  padding: 0 15px 0 0;
}
#footer {
  @include media-breakpoint-down(md){
    .block-contact, .block-contact-depo {
      padding-left: 2px;
    }
    .ci-wrapper {
      div, a {
        font-size: 12px;
      }
    }
    .footer-container li a {
      font-size: 12px;
    }
  }
  @include media-breakpoint-down(xs) {
    .width-40 {
      padding: 0 15px;
    }
    .ci-wrapper {
      height: 0;
    }
    .copyright-container {
      margin-top: 0;
      border-top: 0;
      p.copyright {
        margin-top: 0;
      }
    }
  }
}
.linklist {
  .blockcms-title a {
    color:$gray-darker;
  }
}
.account-list a {
  color: $gray;
  &:hover {
    color: $brand-primary;
  }
}
.blockcms-title,
.myaccount-title,
.myaccount-title a,
.block-contact-title {
  font-weight: 700;
  font-size: $base-font-size;
}
.block-social {
  text-align: right;
}
.block-social li {
  height: 2.5rem;
  width: 2.5rem;
  background-color: $gray-light;
  background-repeat: no-repeat;
  display: inline-block;
  margin: 0.125rem;
  cursor: pointer;
  &:hover {
    background-color: $brand-primary;
  }
  a {
    display: block;
    height: 100%;
    white-space: nowrap;
    text-indent: 100%;
    overflow: hidden;
    &:hover {
      color: transparent;
    }
  }
}
.facebook {
  background-image: url(../img/facebook.svg);
  &::before{
    content:"";
    background-image: url(../img/facebook-blue.svg);
  }
  &.icon-gray {
    background-image: url(../img/facebook-gray.svg);
    &:hover {
      background-image: url(../img/facebook-blue.svg);
    }
  }
}
.twitter {
  background-image: url(../img/twitter.svg);
  &::before{
    content:"";
    background-image: url(../img/twitter-blue.svg);
  }
  &.icon-gray {
    background-image: url(../img/twitter-gray.svg);
    &:hover {
      background-image: url(../img/twitter-blue.svg);
    }
  }
}
.rss {
  background-image: url(../img/rss.svg);
}
.youtube {
  background-image: url(../img/youtube.svg);
}
.googleplus {
  background-image: url(../img/gplus.svg);
  &::before{
    content:"";
    background-image: url(../img/gplus-blue.svg);
  }
  &.icon-gray {
    background-image: url(../img/gplus-gray.svg);
    &:hover {
      background-image: url(../img/gplus-blue.svg);
    }
  }
}
#block_myaccount_infos{
  .myaccount-title a{
    color:$gray-darker;
  }
}
.pinterest {
  background-image: url(../img/pinterest.svg);
  &::before{
    content:"";
    background-image: url(../img/pinterest-blue.svg);
  }
  &.icon-gray {
    background-image: url(../img/pinterest-gray.svg);
    &:hover {
      background-image: url(../img/pinterest-blue.svg);
    }
  }
}
.vimeo {
  background-image: url(../img/vimeo.svg);
}
.instagram {
  background-image: url(../img/instagram.svg);
}
.footer-container {
  overflow: hidden;
  .arrow-right {
    &:before {
      content: '\f0da';
      display: inline-block;
      font-family: $font-awesome;
      font-weight: 900;
      font-size:14px;
    }
  }
  li {
    margin-bottom: 6px;
    font-size:12px;
    &:before {
      content: '\f0da';
      display: inline-block;
      font-family: $font-awesome;
      font-weight: 900;
      font-size:14px;
      color: $brand-secondary;
    }
  }
  li a {
    color: $brand-secondary;
    cursor: pointer;
    font: 500 12px $font-family;
    &:hover {
      color: $brand-primary;
    }
  }
  .links {
    .collapse {
      display: inherit;
    }
  }
  .ci-wrapper {
    div, a {
      font-size: 12px;
      color: $brand-secondary;
      margin-bottom: 10px;
    }
    .view-map {
      border: 1px solid #efefef;
      border-radius: 5px;
    }
  }
}
.footer-padd {
  padding-left: 30px;
}
.footer-title {
  text-transform: uppercase;
  color:#be0c12;
  /*
  font: 600 17px $font-family;
  color: $brand-secondary;
  */
  font: 600 15px $font-family;
  
  margin-bottom: 15px;
  &:after {
    content: '';
    /*
    width: 70px;
    */
    width: 100%;
    border: 0.5px solid $brand-secondary;
    display: block;
    margin-top: 10px;
  }
}
.pl-10 {
  padding-left: 10px;
}

.copyright-container{
  // background-color: #052b45;
  border-top: 1px solid $brand-secondary;
  margin-top: 30px;
  .copyright-wrapper{
    padding-top: 18px;
  }
  .made-by {
    text-align: right;
    //float: right;
  }
  p.copyright {
    margin-bottom: 0;
    margin-top: 12px;
    color: #6b6f74;
    font: 400 13px $font-family;
  }
  p.senior-text {
    font-size: 10px;
  }
  p.rights{
    color: #6b6f74;
    font: 300 14px $font-family;
  }
  .ss-img {
    float: right;
  }
  .itd-img {
    height: 40px;
    padding-right: 10px;
  }
}

/*** custom footer ***/
#footer {
  ul {
    margin-bottom: 0;
  }
  background-color: $bgc;
  padding-top: 40px;
}

#authentication #footer, #password #footer {
  padding: 0;
}

@include media-breakpoint-up(md) {
  .width-33 {
    /*
    width: 32%;
    */
    width: 33.333333%;
    padding:0;
    display: inline-block;
    float: left;
    padding-right: 10px;
  }
  .width-20 {
    width: 20%;
    padding:0;
    display: inline-block;
    float: left;
    padding-right: 10px;
  }
  .width-25 {
    width: 25%;
    padding:0;
    display: inline-block;
    float: left;
    padding-right: 10px;
  }  
  .width-16 {
    width: 16.666667%;
    padding:0;
    display: inline-block;
    float: left;
    padding-right: 10px;
  }
  .width-40 {
    width: 40%;
    padding:0 15px;
    display: inline-block;
  }
}
.ci-wrapper {
  float:right;
  /*
  width: 60%;
  */
  width: 66.666667%;
}
/*** custom footer ***/


@include media-breakpoint-down(sm) {
  .block_newsletter {
    padding-bottom: 0.625rem;
    border-bottom: 1px solid $gray-lighter;
  }
  .footer-container {
    .material-icons {
      color: $brand-secondary;
    }
    box-shadow: none;
    .wrapper {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .links {
      .h3 {
        font-size: 14px;
        margin-top: 10px;
        display: inline-block;
        color: $brand-secondary;
      }
      .title {
        padding: 0 10px;
        border-bottom: 1px solid $gray-lighter;
        cursor: pointer;
        .collapse-icons .remove {
          display: none;
        }
      }
      .title[aria-expanded="true"] .collapse-icons {
        .add {
          display: none;
        }
        .remove {
          display: block;
          color: $brand-secondary;
        }
      }
      .navbar-toggler {
        display: inline-block;
        padding: 0;
        width: 25px;
      }
      .collapse {
        display: none;
        &.in {
          display: block;
        }
      }
      ul {
        margin-bottom: 0;
        > li {
          padding: 5px 0 5px 10px;
          background: transparent;
          margin-bottom: 0;
          &:before {
            color: $brand-secondary;
        }
          a {
            color: $brand-secondary;
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .block-social {
    text-align: center;
  }
  .block-contact {
    padding-left: 1.5rem;
  }
}
