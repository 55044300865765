.ui-autocomplete {
  z-index: 9999999;

  .ui-front {
    background: #fff;
    border: 1px solid #ddd;

    li {
      border-bottom: 1px solid #ddd;
      cursor: pointer;
      display:flex;

      &:last-child {
        border-bottom: none;
      }

      .product-image {
        display: block;
        float: left;
        padding: 10px;

        &:hover {
          border: none;
        }

        img {
          max-width: 100px;
        }
      }

      .product-info {
        display: block;
        float: left;
        padding: 20px;

        &:hover {
          border: none;
        }

        a {
          display: block;
          font: 14px/21px $font-family;
          text-transform: uppercase;
          margin-bottom: 5px;
          color: #000;
          padding:0!important;
        }

        span {
          font-family: $font-family;
          font-size: 14px;

          .no-reduction {
            color: #ec4747;
          }
        }
      }
    }
  }
}
.search-ref {
  font: 500 13px $font-family;
}

#search_block_top #search_query_top.no-round-bottom {
  -moz-border-radius: 32px 32px 0 0;
  -webkit-border-radius: 32px;
  border-radius: 32px 32px 0 0;
}

#ui-id-1.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
  border: 0;
}

.ui-state-focus {
  border:none!important;
  background:none!important;
  padding:10px!important;
}

.ui-menu .ui-menu-item a.ui-state-focus, .ui-menu .ui-menu-item a.ui-state-active {
  margin:0!important;
}

.old-price {
  text-decoration:line-through;
  font-size:12px!important;
  margin-right:5px;
}

.searchresult {
  position: absolute;
  right: 0;
  max-height: 360px;
  overflow: hidden;

  .nano-content {
    position: absolute;
    overflow: scroll;
    overflow-x: hidden;
    top: 0;
    bottom: 0;
    left: 0;

    &:focus {
      outline: thin dotted;
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .nano-pane {
    background: #d7ebf5;
    position: absolute;
    width: 7px;
    height: 360px;
    right: 0;
    top: 0;
    bottom: 0;
    visibility: hidden \9;
    /* Target only IE7 and IE8 with this hack */
    opacity: .4;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    z-index: 9999999;

    .nano-slider {
      background: #21a5e3;
      opacity: .8;
      position: relative;
      margin: 0 1px;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
    }
  }
}

.has-scrollbar .nano-content::-webkit-scrollbar {
  display: block;
}

.searchresult .nano-pane, .searchresult2 .nano-pane {
  background: #ddd;
  position: absolute;
  width: 7px;
  /*height: 298px;*/
  right: 0;
  top: 0;
  bottom: 0;
  visibility: hidden \9;
  opacity: .4;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  z-index: 9999999;
}

.searchresult, .searchresult2 {
  position: absolute;
  max-height: unset;
  overflow: hidden;
  right:0px;
}

.searchresult:hover .nano-pane, .nano-pane.active, .nano-pane.flashed {
  visibility: visible \9;
  /* Target only IE7 and IE8 with this hack */
  opacity: 0.99;
}

.sec_search_widget form{
  position: relative;
  color: $search-gray;
  input[type="search"] {
    color: $search-gray;
    font: 300 14px $font-family;
    border: 1px solid $gray-light;
    width: 100%;
    height: 45px;
    padding-left: 40px;
    outline: none;
    background-color: $gray-lighter;
    border-radius: 30px;
    box-shadow: inset 0 0 20px #dadada;
    &:active, &:focus {
      background-color: lighten($gray-lighter, 20%);
      color: $brand-secondary;
    }
  }
  .search-button{
    position: absolute;
    top: 4px;
    right: 5px;
    background-color: #fff;
    color: #fff;
    height: 37px;
    width: 65px;
    border: none;
    cursor: pointer;
    padding: 0;
    outline: none;
    color: $gray-dark;
    border-radius: 20px;
  }
  input::placeholder {
    color: $search-gray;
    opacity: 1;
  }
}

#_mobile_search_trigger {
  //margin-right: 10px;
  cursor: pointer;
  font-size: 17px;
}

.spinner-icon {
  display: none;
}
.close-icon {
  padding-left: 10px;
  padding-right: 10px;
  display: block;
  position: absolute;
  right: 50px;
  top: 0px;
  bottom: 0;
  padding-top: 15px;
  cursor: pointer;
}
.hide-search {
  display: none;
}
.show-spinner {
  display: block;
  position: absolute;
  top: 14px;
  right: 80px;
  bottom: 0;
  z-index: 9999;
}
.modal-search {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  .sec_search_widget {
      display: block;
      float: none;
      margin-top: 50px;
  }
}
.ui-autocomplete.ui-front li .product-info span.no-reduction {
  color: $brand-primary !important;
}

.js-cat-name {
  font: 500 14px $font-family;
}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  *::-ms-clear, *:-ms-reveal {
      display: none;
  }
  .searchresult {
    -ms-overflow-style: none!important;
    overflow: auto!important;
  }
}

@include media-breakpoint-down(sm) {
  .sec_search_widget {
    display: none;
  }
  .sec_search_widget form .search-button {
    width: 45px;
  }
}