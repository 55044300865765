
#products, .featured-products, .product-accessories, .product-miniature {
  .products {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .product-thumbnail {
    display: block;
    text-align: center;
  }
  //.product-title a {
  //  color: $gray;
  //  font-size: $font-size-sm;
  //  text-decoration: none;
  //  text-align: center;
  //  font-weight: 400;
  //}
  .thumbnail-container {
    position: relative;
    margin-bottom: 20px;
    background: white;
    border: 1px solid $gray;
    &:hover {
    .info-sign{
      border-color: #b07274;
      &:before {
        background-image: url("../img//info-sign-pink.png");
      }
      &:hover {
        .info-tooltip {
          display: inline-block;
          z-index: 99;
        }
      }
    }
    }

  }

  .info-sign {
    width: 20px;
    height: 20px;
    display: block;
    border: 1px solid $gray;
    position: absolute;
    right: 10px;
    top: 12px;
    text-align: center;
    padding-top: 2px;
    padding-left: 6px;
    cursor: pointer;
    &:before {
      display: block;
      content: '';
      background-image: url("../img//info-sign.png");
      background-repeat: no-repeat;
      width: 10px;
      height: 15px;
    }
  }

  .info-tooltip {
    display: none;
    width: 100px;
    position: absolute;
    right: 30px;
    top: -3px;
    font-size: 12px;
    background-color: #b07274;
    font-weight: 300;
    padding: 2px 5px;
    color: #fff;
    .ttp-text {
      position: relative;
      a {
        color: #fff;
      }
    }
    .ttp-arrow {
      position: absolute;
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 10px solid #b07274;
      position: absolute;
      right: -10px;
      top: 0;
    }
  }

  .products-section-title {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .product-title {
    text-align: center;
    text-transform: capitalize;
    max-height: 59px;
    min-height: 59px;
    overflow: hidden;
    margin-bottom: 15px;
  }

  .product-price-and-shipping {
    color: $gray-darker;
    font-weight: 500;
    text-align: center;
    height: 55px;
    .price {
      font: 600 16px $font-family;
      color: $brand-primary;
      display: inline-block;
      @include media-breakpoint-down(lg){
        display: block;
      }
      @include media-breakpoint-down(sm) {
        display: inline-block;
      }
      @include media-breakpoint-down(xs) {
        display: block;
      }
    }
    .regular-price {
      font: 300 12px $font-family;
      color: $price-gray;
      display: inline-block;
    }
  }
  .product_show_list {
    .price {
      display: inline-block !important;
    }
    .info-container  {
      display: none;
    }
  }

  .add-to-cart-listing-btn {
    border-radius: 20px;
    font: 300 11px $font-family;
    box-shadow: none;
    padding: 7px 15px;
    text-transform: none;
    margin-top: 5px;
    text-align: center;
  }

  .variant-links {
    position: relative;
    text-align: center;
    width: 100%;
    top: -0.25em;
    padding-top: 0.1875rem;
    min-height: 2.5rem;
    background: white;
  }

  .highlighted-informations {
    position: absolute;
    bottom: 1.25rem;
    padding-top: 0.625rem;
    z-index: 0;
    background: white;
    text-align: center;
    width: 257px;
    height: 3.125rem;
    box-shadow: 0 -5px 10px -5px rgba(0, 0, 0, 0.2);
    transition: bottom .3s;
    .quick-view {
      color: $gray;
      font-size: $base-font-size;
      &:hover {
        color: $brand-primary;
      }
    }
  }

  .product-description {
    position: relative;
    z-index: 1;
    background: white;
    text-align: center;
    width: 100%;
    bottom: 0;
    margin-bottom: 5px;
  }

  img {
    width: 100%;
    padding: 5px 20px;
  }

  //.product-miniature {
  //margin: 0 0.625rem;
  .discount {
    display: none;
  }
  .pack {
    display: none;
  }
  //.product-flags .new,
  //.online-only,
  //.on-sale,
  //.discount-percentage {
  //  display: block;
  //  position: absolute;
  //  color: white;
  //  background: $brand-secondary;
  //  text-transform: uppercase;
  //  border-top-right-radius: 15px;
  //  border-bottom-right-radius: 15px;
  //  border-bottom-left-radius: 15px;
  //  top: 0;
  //  width: 50px;
  //  height: 25px;
  //  font-size: 8px;
  //  font-weight: 300;
  //  text-align: center;
  //  padding: 3px 10px;
  //  left: 10px;
  //  box-shadow: none;
  //  @include media-breakpoint-down(sm) {
  //    font-size: 11px;
  //  }
  //  &.on-sale {
  //    background: $brand-secondary;
  //    width: 100%;
  //    text-align: center;
  //    left: 0;
  //    top: 0;
  //  }
  //  &.online-only {
  //    font-size: $font-size-xs;
  //    margin-top: 13rem;
  //    margin-left: 8.688rem;
  //    &::before {
  //      content: "\E30A";
  //      font-family: 'Material Icons';
  //      vertical-align: middle;
  //      margin: 0.3125rem;
  //    }
  //  }
  //}


.product-flags {
  //display: flex;
  //flex-flow: column wrap;
  //width: 100%;
  .product-flag, .flag-percentage, .flag-amount {
    display: flex;
    position: relative;
    color: white;
    background: $brand-secondary;
    text-transform: uppercase;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    top: 0;
    width: 50px;
    height: 25px;
    font-size: 10px;
    font-weight: 300;
    text-align: center;
    padding: 3px 12px;
    left: 10px;
    box-shadow: none;
    &.discount, &.pack {
      display: none;
    }
  }
  .new {
    display: block;
    order: 1;
    padding-top: 6px;
  }
  .reduction {
    order: 2;
    display: block;
    margin-top: 8px;
    background: $brand-primary;
      padding: 3px 0px;
  }
  .amount {
    order: 3;
    display: block;
    margin-top: 8px;
    background: $brand-primary;
  }
}
  .discount-product {
    display: none;
  }

  .comments_note {
    text-align: center;
    color: $gray;
  }

  .regular-price {
    color: $gray;
    text-decoration: line-through;
    font-size: $font-size-sm;
  }

  .count {
    color: $gray;
    font-weight: 700;
    position: relative;
    bottom: 0.5rem;
  }

  .all-product-link {
    clear: both;
    color: $gray;
    font-weight: 700;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

}

#index {
  .discount-product {
    display: none;
  }
}

.productsliders_block {
  padding-bottom: 20px;
  padding-top: 30px;
  background-color: $bgc;
}

.slider-title {
  margin-bottom: 35px;
  padding-bottom: 15px;
  border-bottom: 2px solid $gray;
  position: relative;
  font: 300 20px $font-family;
  text-transform: none;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 180px;
    border-bottom: 2px solid $brand-primary;
    top: 39px;
  }
}


.slick-next:before {
  content: '' !important;
  background-image: url('../img/arrow-right.png');
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  display: block;
}

.slick-prev:before {
  content: '' !important;
  background-image: url('../img/arrow-left.png');
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  display: block;
}
.similar-products, .productsliders_block, .product-accessories, .modrp-resealed-products {
  .slick-next {
    right: 0 !important;
  }
  .slick-prev {
    left: auto;
    right: 25px !important;
  }
}
.slider-container {
  .slick-prev, .slick-next {
    top: -63px !important;
  }
}
.category-products, .product-accessories, .modrp-resealed-products {
  .slick-prev, .slick-next {
    top: -67px !important;
  }

}

.product-miniature {
  .add-to-cart-listing {
    .input-group bootstrap-touchspin {
      float: none;
    }
    .qty {
      display: block;
      float: none;
      width: 100%;
      text-align: center;
    }
    input {
      text-align: center;
      font-size: 13px;
      color: $gray;
    }
    .input-group bootstrap-touchspin {
      float: none;
      position: relative;
    }
    .bootstrap-touchspin {
      float: none;
    }
    .bootstrap-touchspin .input-group-btn-vertical > .btn {
      position: absolute;
      width: 30px;
      height: 27px;
      padding: 8px 13px;
    }
    .bootstrap-touchspin-up {
      left: 0;
      border-top-right-radius: 14px;
      border-bottom-right-radius: 14px;
    }
    .bootstrap-touchspin-down {
      left: -74px;
      top: 2px;
      border-top-left-radius: 14px;
      border-bottom-left-radius: 14px;
    }
    .bootstrap-touchspin input.form-control, .bootstrap-touchspin input.input-group {
      height: 27px;
      width: 48px;
    }
    .bootstrap-touchspin .input-group-btn-vertical i {
      top: 0;
      left: 5px;
      font-size: 15px;
    }
    .bootstrap-touchspin .input-group-btn-vertical .touchspin-up:after {
      content: '\f067';
      font-family: $font-awesome;
      font-weight: 900;
      font-size: 8px;
      color: $gray;
    }
    .bootstrap-touchspin .input-group-btn-vertical .touchspin-down:after {
      content: '\f068';
      font-family: $font-awesome;
      font-weight: 900;
      font-size: 8px;
      color: $gray;
    }
  }
}

@include media-breakpoint-down(sm) {
  .info-container {
    display: none;
  }
}
