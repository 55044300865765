
li#favoriteproducts_block_extra_add {
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    color: $brand-secondary;
}

li#favoriteproducts_block_extra_remove {
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    color: $brand-secondary;
}

.no-fav-prods {
    text-align: left;
    padding-top: 10px;
    font: 400 14px $font-family;
}

.fav-info {
    cursor: pointer;
}

.dd-fav-button {
    @include button;
    margin-top: 5px;
}

.dd-fav-button:hover, .dd-fav-button:active, .dd-fav-button:hover:active {
    background-color: $brand-primary;
    color: #fff !important;
}

.dd-fav {
    height: 40px;
    z-index: 99;
    position: relative;
}

.fav-notlogged {
    padding: 15px;
    text-align: left;
    font-size: 12px;
}

//favorites expanded
#favorites {
    display: none;
    position: absolute;
    margin-top: 17px;
    left: -60px;
    top: 20px;
    width: 240px;
    background: white;
    z-index: 100 !important;
    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07);
    -webkit-box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07);
    -webkit-transition-property: color, background-color, -webkit-box-shadow, -webkit-transform;
    transition-property: color, background-color, -webkit-box-shadow, -webkit-transform;
    transition-property: color, background-color, box-shadow, transform;
    transition-property: color, background-color, box-shadow, transform, -webkit-box-shadow, -webkit-transform;
    -webkit-transition-duration: .15s;
    transition-duration: .15s;
    letter-spacing: 0;
    div.arrow-up {
        width: 100%;
        &:before {
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 10px solid $gray;
            content: " ";
            display: block;
            top: -11px;
            right: 42px;
            position: absolute;
        }
    }
    .nano {
        height: 145px;
        position: relative;
        width: 240px;
    }
    .nano-content {
        position: absolute;
        overflow: scroll;
        overflow-x: hidden;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin-bottom: 0;
    }
    .nano > .nano-pane {
        background: #595a5c;
        position: absolute;
        width: 6px;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: .4;
        -webkit-transition: .2s;
        -moz-transition: .2s;
        -o-transition: .2s;
        transition: .2s;
        display: block;
    }
    .nano > .nano-pane > .nano-slider {
        background: #fff;
        opacity: .8;
        position: relative;
        margin: 0 1px;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        display: block
    }

    .view-fav {
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: #f6f6f6;
        a {
            font: 400 14px $font-family;
            padding: 5px 10px;
            color: #fff;
            text-transform: none;
            box-shadow: none;
        }
    }
    .media-list {
        border-bottom: 1px solid #ddd;
        padding-bottom: 5px;
        padding-top: 5px;
        margin-bottom: 0;
    }
    .media-object {
        width: 50px;
    }
    .media-body a {
        font: 400 11px/13px $font-family;
        float: left;
        text-align: left;
    }
}

//favorites expanded

//design like product miniature
.fav-pg-title {
    margin-bottom: 20px;
}

.favoriteproduct {
    .fav-container {
        position: relative;
        margin-bottom: 20px;
        background: white;
        border: 1px solid #cdcdcd;
        padding: 15px;
    }
    img {
        width: 100%;
    }
    .favorite-title {
        text-align: center;
        height: 40px;
        overflow: hidden;
        a {
            font: 700 14px $font-family;
            color: #000;
        }
    }
    .remove-favorite {
        text-align: center;
        button {
            border-radius: 20px;
            font: 400 11px $font-family;
            box-shadow: none;
            padding: 7px 15px;
            text-transform: none;
            margin-top: 5px;
            text-align: center;
            cursor: pointer;
            background-color: $brand-primary;
            border: none;
            color: #fff;
        }
    }
}