//Sortare Mobil
@include media-breakpoint-down(sm){
    #category, #search, #manufacturer, #prices-drop {
        .dropdown-menu {
            position: fixed;
            top: 17px;
            left: 15px;
            bottom: 45%;
            right: 15px;
            width: auto;
            padding-top: 15px;
            background-color: #fff;
            border-radius: 3px;
            height: 335px;
            a {
                color: gray;
                // text-align: center;
                font-size: 15px;
                padding: 10px 15px;
                &:hover {
                    background-color: transparent;
                }
                &.current {
                    cursor: initial;
                    color: $brand-primary;
                }
            }
        }
        .select-title {
            font: 400 14px $font-family;
            padding: 5px;
            border: 1px solid $gray;
            background-color: transparent;
            text-align: center;
            color: $gray-darker;
            text-transform: none;
            box-shadow: none;
            border-radius: 3px;
        }
    }
    .sort-by-header {
        font: 400 16px $font-family;
        padding-left: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid gray;
        color: gray;
    }
    .close-filter-icon {
        display: inline-block;
        float: right;
        padding-right: 15px;
        cursor: pointer;
    }
}
//Sortare Mobil